import { CommonModule } from '@angular/common';
import { MessageComponent } from './message.component';
import { MessagesModule } from 'primeng/messages';
import { NgModule } from '@angular/core';
import { MessageModule as PrimeNgMessageModule } from 'primeng/message';
import { SharedModule } from 'primeng/api';
import { SvgIconModule } from '../../non-primeng/svg-icon/svg-icon.module';

@NgModule({
  declarations: [MessageComponent],
  imports: [CommonModule, MessagesModule, PrimeNgMessageModule, SharedModule, SvgIconModule],
  exports: [MessageComponent],
})
export class MessageModule {}
