<label class="mb-2 flex" [htmlFor]="inputId ?? ''">
    <span class="inline-flex">
        <p-badge *ngIf="!!badge" [value]="badge" [styleClass]="badgeStyleClass"></p-badge>
        <span *ngIf="label" class="self-center empty:hidden">{{ label }}</span>
        <span *ngIf="!label" class="self-center empty:hidden"><ng-content></ng-content></span>
    </span>
    <ng-container *ngIf="trailing">
        <em>{{ trailing }}</em>
    </ng-container>
</label>
