import { Accent, VisualLevel } from './base-elements.model';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'ui-button-legacy',
  template: `<button [disabled]="disabled" [ngClass]="_class" [style]="elementStyle" (click)="handleClick($event)">
    <ng-content></ng-content>
  </button>`,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnInit {
  @Input() elementStyle?: string;
  @Input() accent?: Accent;
  @Input() level: VisualLevel = 'faced';
  @Input() effect!: string;
  @Input()
  @HostBinding('class.--disabled')
  disabled = false;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() click = new EventEmitter();

  _class = '';

  handleClick(e: any): void {
    e.preventDefault();
    e.stopPropagation();
    if (!this.disabled) {
      this.click.next(e);
    }
  }

  ngOnInit() {
    this._class = `dc-btn`;
    if (this.accent) this._class += ` dc-btn--${this.accent}`;
    if (this.level) this._class += ` dc-btn--${this.level}`;
    if (this.effect === 'pulse') this._class += ` btn-effect--pulse`;
  }
}
