import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { ENVIRONMENT } from '@abstractions';
import { Observable } from 'rxjs';

@Injectable()
export class ApiPrefixInterceptor implements HttpInterceptor {
  envConf = inject(ENVIRONMENT);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (typeof req.url === 'string' && req.url.indexOf('://') === -1) {
      const modified = req.clone({
        url: [this.envConf.apiUriPrefix, req.url].join(''),
      });
      return next.handle(modified);
    }
    return next.handle(req);
  }
}
