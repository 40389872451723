import { Pipe, PipeTransform } from '@angular/core';

const monthLabels = [
  'ledna',
  'února',
  'března',
  'dubna',
  'května',
  'června',
  'července',
  'srpna',
  'září',
  'října',
  'listopadu',
  'prosince',
];

@Pipe({ name: 'strDateTime' })
export class StrDateTimePipe implements PipeTransform {
  transform(value: string): string | undefined {
    if (!value) return value;

    const m = value.match(/^(\d{4})-(\d{1,2})-(\d{1,2})[T\ ]?(\d{1,2})?:?(\d{1,2})?:?(\d{1,2})?(.*)$/);

    if (m === null) {
      return value;
    }

    const day = parseInt(m[3], 10);
    const month = parseInt(m[2], 10);
    const year = parseInt(m[1], 10);
    const hours = m[4];
    const minutes = m[5];

    const a = new Date();

    const title = `${day}. ${monthLabels[month - 1]} ${year} ${hours}:${minutes}`;

    if (year === a.getFullYear() && month === a.getMonth() + 1 && day === a.getDate()) {
      return `<span title="${title}">dnes ${hours}:${minutes}</span>`;
    } else {
      return `<span title="${title}">${m[3]}.${m[2]}.${year} ${hours}:${minutes}</span>`;
    }
  }
}
