import { EntityDataModule, EntityDataService } from '@ngrx/data';
import { entityMetadata, userAccountDiOpt, userAccountOpt } from './user-account.model';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UserAccountService } from './user-account.service';
import { UsernameAccountPipe } from './username-account.pipe';

@NgModule({
  declarations: [UsernameAccountPipe],
  providers: [
    UserAccountService,
    {
      provide: userAccountDiOpt,
      useValue: userAccountOpt,
    },
  ],
  imports: [
    CommonModule,
    EntityDataModule.forRoot({
      entityMetadata,
    }),
  ],
  exports: [UsernameAccountPipe],
})
export class UserAccountModule {
  constructor(data: EntityDataService, service: UserAccountService) {
    data.registerService('UserAccount', service);
  }
}
