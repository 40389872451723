import { DateTimeFormat } from '@domain';

export const translations = {
  firstDayOfWeek: 1,
  dateFormat: DateTimeFormat.DatePrimeNG,
  dayNamesMin: ['Ne', 'Po', 'Út', 'St', 'Čt', 'Pá', 'So'],
  monthNames: [
    'Leden',
    'Únor',
    'Březen',
    'Duben',
    'Květen',
    'Červen',
    'Červenec',
    'Srpen',
    'Září',
    'Říjen',
    'Listopad',
    'Prosinec',
  ],
  monthNamesShort: ['Led', 'Úno', 'Bře', 'Dub', 'Kvě', 'Čvn', 'Čvc', 'Srp', 'Zář', 'Říj', 'Lis', 'Pro'],
  today: 'Dnes',
  clear: 'Vymazat',
  startsWith: 'Začíná',
  contains: 'Obsahuje',
  notContains: 'Neobsahuje',
  endsWith: 'Končí',
  equals: 'Je rovno',
  notEquals: 'Není rovno',
  noFilter: 'Žádný filtr',
  lt: 'Menší než',
  lte: 'Menší než nebo rovno',
  gt: 'Větší než',
  gte: 'Větší než nebo rovno',
  is: 'Je',
  isNot: 'Není',
  before: 'Před',
  after: 'Po',
  dateIs: 'Je rovno',
  dateIsNot: 'Není rovno',
  dateBefore: 'Před',
  dateAfter: 'Po',
  apply: 'Použít',
  addRule: 'Přidat',
  removeRule: 'Odebrat',
  emptyMessage: 'Žádné výsledky',
  emptyFilterMessage: 'Žádné výsledky',
};
