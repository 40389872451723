import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ToastMessageType, ToastService, ToastType } from '@abstractions';
import { catchError, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';

@Injectable()
export class ServiceUnavailableErrorInterceptor implements HttpInterceptor {
  private deployNotificationId: string | undefined = undefined;

  constructor(private toast: ToastService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((resp: any) => {
        if (resp?.ok && this.deployNotificationId) {
          this.toast.close(this.deployNotificationId);
          this.deployNotificationId = undefined;
        }
      }),
      catchError((error) => {
        if (error.status === 503 && !this.deployNotificationId) {
          this.deployNotificationId = this.toast.add({
            severity: ToastType.Fatal,
            sticky: true,
            summary: 'Nedostupné služby.',
            detail: 'Zkuste se obrátit na IT support.',
            data: { code: ToastMessageType.ServiceUnavailable },
          });
        }
        return throwError(error);
      }),
    );
  }
}
