import { AbstractLogger } from './abstract/abstract-logger';
import { Injectable } from '@angular/core';
import { Logger } from './abstract/logger';

@Injectable()
export class LoggerService implements Logger {
  constructor(private readonly logger: AbstractLogger) {}

  error(logMessage: string, error?: Error): void {
    this.logger.error(logMessage, error);
  }

  info(logMessage: string): void {
    this.logger.info(logMessage);
  }

  log(logMessage: string): void {
    this.logger.log(logMessage);
  }

  warn(logMessage: string): void {
    this.logger.warn(logMessage);
  }
}
