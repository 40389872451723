import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  TemplateRef,
} from '@angular/core';

import { AppService } from '@utils';

@Component({
  selector: 'ui-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogComponent implements OnDestroy {
  @Input() loading = false;
  @Input() storing = false;
  @Input() display = false;
  @Input() disabled = false;
  @Input() header = '';
  @Input() closable = true;
  @Input() maximizable = false;
  @Input() closeOnEsc = true;
  @Input() draggable = false;
  @Input() showAcceptButton = true;
  @Input() acceptButtonLabel = 'Potvrdit';
  @Input() acceptButtonTooltip!: string;
  @Input() showRejectButton = true;
  @Input() rejectButtonLabel = 'Zavřít';
  @Input() styleClass: string = 'min-w-[25vw]';
  @Input() contentClass!: string;
  @Input() position!:
    | 'center'
    | 'top'
    | 'bottom'
    | 'left'
    | 'right'
    | 'topleft'
    | 'topright'
    | 'bottomleft'
    | 'bottomright';
  @Input() blocked = false;
  @Input() appendTo?: HTMLElement | ElementRef | TemplateRef<any> | string;

  @Output() readonly accept = new EventEmitter<void>();
  @Output() readonly reject = new EventEmitter<void>();
  @Output() readonly appear = new EventEmitter<void>();
  @Output() readonly disappear = new EventEmitter<void>();
  @Output() readonly permanent = new EventEmitter<boolean>();

  constructor(
    private app: AppService,
    private renderer: Renderer2,
  ) {}

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'ui-dialog--active');
    this.onDisappear();
  }

  onAccept(): void {
    this.accept.emit();
  }

  onReject(): void {
    this.reject.emit();
  }

  onAppear(): void {
    this.app.hideBodyScrollBar();
    this.renderer.addClass(document.body, 'ui-dialog--active');
    this.appear.emit();
  }

  onDisappear(): void {
    this.app.showBodyScrollBar();
    this.disappear.emit();
  }
}
