import { AddressState, AddressSuggestion, ValidateAddressResult } from './address.model';
import { BehaviorSubject, Observable } from 'rxjs';

import { AddressApiService } from './address-api.service';
import { Injectable } from '@angular/core';

@Injectable()
export class AddressService {
  state$: BehaviorSubject<AddressState>;

  constructor(private api: AddressApiService) {
    this.state$ = new BehaviorSubject<AddressState>({
      address: {},
    });
  }

  checkAddress(address: any): Observable<ValidateAddressResult> {
    return this.api.checkAddress(address);
  }

  lookupAddress(address: any, attribute: string): Observable<any> {
    return this.api.lookupAddress(address, attribute);
  }

  pickAddress(suggestedAddress: AddressSuggestion): AddressSuggestion {
    const address = this.state$.value.address;
    ['city', 'street', 'streetNumber', 'zipCode'].forEach((item) => {
      if (!address[item] && !!suggestedAddress[item]) {
        address[item] = suggestedAddress[item];
        if (item === 'zipCode') {
          address['zipCode'] = suggestedAddress[item];
        }
      }
    });
    return address;
  }
}
