export const email =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const phone = /^[+]?[()/0-9. -]{9,}$/;

export const zipCode = /^[0-9]{5}$/;

export const parseISOTime = /(\d{1,2}):(\d{1,2})/;

export const bankAccount = /^(([0-9]{0,6})-)?([0-9]{2,10})$/;

export const bankCode = /^([0-9]{4})$/;

export const variableSymbol = /^([0-9]{1,10})$/;

export const parseUserEntryTime = /(^([0-9]|[0-1][0-9]|[2][0-3]):([0-5][0-9])$)|(^([0-9]|[1][0-9]|[2][0-3])$)/;

export const parseUserEntryDate = /^(0?[1-9]|[12][0-9]|3[01])\. ?(0?[1-9]|1[0-2])\. ?(19[0-9]{2}|20[0-9]{2})$/;

export const parseISODate = /(\d{4})-(\d{2})-(\d{2})/;

export const parseISODateTime = /(\d{4})-(\d{2})-(\d{2})[T ](\d{2}):(\d{2})(.*)/;

export const onlyNumber = /^[0-9]*$/;
