import { CallState, LoadingState, getError } from '@domain';

import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';

export interface Loadable {
  callState: CallState;
}

export abstract class DCAbstractStore<T extends Loadable> extends ComponentStore<T> {
  private readonly defaultState: T;

  protected constructor(defaultState: T) {
    super(defaultState);
    this.defaultState = defaultState;
  }

  // SELECTORS
  readonly loading$: Observable<boolean> = this.select((state) => state.callState === LoadingState.LOADING);
  readonly storing$: Observable<boolean> = this.select((state) => state.callState === LoadingState.STORING);
  readonly stored$: Observable<boolean> = this.select((state) => state.callState === LoadingState.STORED);
  readonly error$: Observable<string | null> = this.select((state) => getError(state.callState));

  // ViewModel for the component
  readonly callStateVm$ = this.select(this.loading$, this.storing$, this.error$, (loading, storing, error) => ({
    loading,
    storing,
    error,
  }));

  readonly reset = this.updater(() => Object.assign({}, this.defaultState));

  readonly initCallState = this.updater((state) => {
    return {
      ...state,
      callState: LoadingState.INIT,
    };
  });

  readonly updateError = this.updater((state, error: string) => {
    return {
      ...state,
      callState: {
        errorMsg: error,
      },
    };
  });

  readonly setLoading = this.updater((state) => {
    return {
      ...state,
      callState: LoadingState.LOADING,
    };
  });

  readonly setStoring = this.updater((state) => {
    return {
      ...state,
      callState: LoadingState.STORING,
    };
  });

  readonly setLoaded = this.updater((state) => {
    return {
      ...state,
      callState: LoadingState.LOADED,
    };
  });

  readonly setStored = this.updater((state) => {
    return {
      ...state,
      callState: LoadingState.STORED,
    };
  });
}
