import { Account, KeycloakLoginResponse, UserOptions } from './model/user.model';
import { Inject, Injectable } from '@angular/core';

import { ApiService } from '../api/api.service';
import { Observable } from 'rxjs';
import UserUtils from './user.util';
// @ts-ignore
import jwt_decode from 'jwt-decode';
import { map } from 'rxjs/operators';
import { userOptionsDI } from './user.di';

@Injectable()
export class UserAPIService {
  static doDecodeUserResponseByToken(accessToken: KeycloakLoginResponse): any {
    const decodedAccessToken = jwt_decode(accessToken.accessToken);
    const rights = UserUtils.mapRights(decodedAccessToken);

    const expiration = decodedAccessToken.exp;
    let d = new Date(0); // The 0 there is the key, which sets the date to the epoch
    d.setUTCSeconds(expiration);
    d.setMinutes(d.getMinutes() - 1); // refresh 1 minute earlier
    const expirationTime = d.getTime();

    return {
      accessToken: accessToken.accessToken,
      accessTokenExp: expiration,
      refreshToken: accessToken.refreshToken,
      profile: {
        username: decodedAccessToken.preferred_username || '-',
        realName: `${decodedAccessToken.given_name || '-'} ${decodedAccessToken.family_name || '-'}`,
        title: null,
        entryPage: ['dashboard'],
        // avatar: data.user.internalPhoto,
        voipexNumber: null,
      },
      username: decodedAccessToken.preferred_username,
      memberOf: rights,
      userAccountId: decodedAccessToken.account_id,
      accessTokenLifeTime: `${expirationTime + 7 * 24 * 60 * 60 * 1000}`,
      accessTokenExpiringTime: expirationTime,
      isLoggedSince: +new Date(),
      state: 'logged',
      errors: [],
    };
  }

  constructor(
    @Inject(userOptionsDI) private opt: UserOptions,
    private api: ApiService,
  ) {}

  getAccounts(): Observable<Account[]> {
    return this.api.get(this.opt.getAccounts).pipe(
      map((responseData: any) => {
        return responseData.accounts;
      }),
    );
  }

  getApiKey(): Observable<string> {
    return this.api.get(this.opt.getApiKey).pipe(map((r: any) => r.apiKey));
  }
}
