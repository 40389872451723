import { EntityMetadataMap } from '@ngrx/data';
import { InjectionToken } from '@angular/core';

export const userAccountDiOpt = new InjectionToken<UserAccountOpt>('userAccountOpt');

export const userAccountOpt = {
  getUserAccountPath: `/api/latest/rest/core/v2/user/account/id/#{accountId}`,
};

export interface UserAccountOpt {
  getUserAccountPath: string;
}

export interface UserAccount {
  id: number;
  description: string;
  email: string;
  internalPhoto?: string;
  firstName: string;
  lastName: string;
  partnerId: string;
  phone: string;
  userAccountGuid: string;
  username: string;
  validityStatusCode: string;
  workPosition?: string;
}

export const entityMetadata: EntityMetadataMap = {
  UserAccount: {
    selectId: (statement) => statement.id,
  },
};
