import { NgClass, NgIf } from '@angular/common';

import { BadgeModule } from 'primeng/badge';
import { LabelComponent } from './label.component';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [BadgeModule, NgIf, NgClass],
  declarations: [LabelComponent],
  exports: [LabelComponent],
})
export class LabelModule {}
