<div
    class="relative min-h-[4.75rem] rounded-xl shadow-sm"
    [ngClass]="
        twMerge(
            colorMode === 'gray' && !styleClass ? 'bg-gray-50' : '',
            colorMode === 'blue' ? 'bg-white' : '',
            styleClass || ''
        )
    "
>
    <ng-content></ng-content>
</div>
