<div *ngIf="showButton" class="floating-boundary" [ngClass]="{ active: isDragged }">
    <button
        (cdkDragStarted)="drag($event)"
        (cdkDragEnded)="drop($event)"
        (click)="openModal()"
        [ngStyle]="calculatePosition()"
        cdkDragBoundary=".floating-boundary"
        class="floating-button"
        cdkDrag
    >
        <img src="/assets/images/icons--SVG/heart.svg" />
    </button>
</div>
