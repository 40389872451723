import { NgClass, NgStyle } from '@angular/common';

import { NgModule } from '@angular/core';
import { SvgIconComponent } from './svg-icon.component';

@NgModule({
  imports: [NgStyle, NgClass],
  declarations: [SvgIconComponent],
  exports: [SvgIconComponent],
})
export class SvgIconModule {}
