import { BehaviorSubject, Observable } from 'rxjs';
import {
  DateTimeObj,
  createNumericRange,
  getCalendarDaysInMonth,
  getNowDateTimeObj,
  isValidDateTimeObject,
} from '@utils';
import { DateTimePickerInstanceState, DateTimePickerState } from './date-time-picker.model';
import { filter, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';

@Injectable()
export class DateTimePickerService {
  state$: BehaviorSubject<DateTimePickerState>;

  constructor() {
    this.state$ = new BehaviorSubject<DateTimePickerState>({});
  }

  getState$(instanceId: string): Observable<DateTimePickerInstanceState> {
    return this.state$.pipe(
      map((s) => s[instanceId]),
      filter((s) => typeof s !== 'undefined'),
    );
  }

  getValueChanges$(instanceId: string): Observable<DateTimeObj> {
    return this.state$.pipe(
      map((s) => s[instanceId] && s[instanceId].value),
      filter((s) => typeof s !== 'undefined'),
    ) as Observable<DateTimeObj>;
  }

  initInstance(instanceId: string, initialDateTime: DateTimeObj) {
    if (!isValidDateTimeObject(initialDateTime)) {
      initialDateTime = getNowDateTimeObj();
    }

    const now = new Date();
    this.state$.next({
      ...this.state$.value,
      [instanceId]: {
        value: initialDateTime,
        days: getCalendarDaysInMonth(initialDateTime.month, initialDateTime.year),
        avaibleMonths: createNumericRange(1, 12),
        avaibleYears: createNumericRange(1918, new Date().getFullYear() + 20),
        avaibleHours: createNumericRange(0, 23),
        avaibleMinutes: createNumericRange(0, 59),
        now: {
          year: now.getFullYear(),
          month: now.getMonth() + 1,
          day: now.getDate(),
        },
      },
    });
  }

  selectDay(instanceId: string, day: any) {
    const state = this.state$.value;
    // @ts-ignore
    state[instanceId].value.day = day.value;
    this.state$.next({ ...state });
  }

  selectMonth(instanceId: string, month: number) {
    const state = this.state$.value;
    // @ts-ignore
    state[instanceId].value.month = month;
    // @ts-ignore
    state[instanceId].days = getCalendarDaysInMonth(month, state[instanceId].value.year);
    this.state$.next({ ...state });
  }

  selectYear(instanceId: string, year: number) {
    const state = this.state$.value;
    // @ts-ignore
    state[instanceId].value.year = year;
    // @ts-ignore
    state[instanceId].days = getCalendarDaysInMonth(state[instanceId].value.month, year);
    this.state$.next({ ...state });
  }

  selectHour(instanceId: string, hours: number) {
    const state = this.state$.value;
    // @ts-ignore
    state[instanceId].value.hours = hours;
    this.state$.next({ ...state });
  }

  selectMinute(instanceId: string, minutes: number) {
    const state = this.state$.value;
    // @ts-ignore
    state[instanceId].value.minutes = minutes;
    this.state$.next({ ...state });
  }

  selectNextMonth(instanceId: string): void {
    const state = this.state$.value;
    const value = state[instanceId].value;
    if (value?.month === 1) {
      value.month = 12;
      --value.year;
    } else {
      // @ts-ignore
      --value.month;
    }
    // @ts-ignore
    state[instanceId].days = getCalendarDaysInMonth(value.month, value.year);
    this.state$.next({ ...state });
  }

  selectPreviousMonth(instanceId: string): void {
    const state = this.state$.value;
    const value = state[instanceId].value;
    if (value?.month === 12) {
      value.month = 1;
      ++value.year;
    } else {
      // @ts-ignore
      ++value.month;
    }
    // @ts-ignore
    state[instanceId].days = getCalendarDaysInMonth(value.month, value.year);
    this.state$.next({ ...state });
  }

  setNowDateTime(instanceId: string): void {
    const state = this.state$.value;
    const value = getNowDateTimeObj();
    state[instanceId].value = value;
    state[instanceId].days = getCalendarDaysInMonth(value.month, value.year);
    this.state$.next({ ...state });
  }
}
