import { AbstractControl, FormGroupDirective } from '@angular/forms';
import { AfterViewInit, Directive, Input, OnChanges, OnDestroy } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Directive({
  selector: '[uiFormFieldMessagesLegacy]',
})
export class FormFieldMessagesDirective implements OnChanges, AfterViewInit, OnDestroy {
  @Input('uiFormFieldMessagesLegacy') formControlName!: string;

  subject = new BehaviorSubject<any>(null);
  control!: AbstractControl | null;

  constructor(private form: FormGroupDirective) {}

  ngOnChanges() {
    this.control = this.form.control.get(this.formControlName);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      // this.checkStatus();
      // this.control.statusChanges.subscribe(this.checkStatus.bind(this));
    });
  }

  ngOnDestroy() {
    this.subject.unsubscribe();
  }
}
