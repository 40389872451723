import { CommonModule } from '@angular/common';
import { ModalBodyComponent } from './modal-body.component';
import { ModalCloseComponent } from './modal-close.component';
import { ModalComponent } from './modal.component';
import { ModalFooterComponent } from './modal-footer.component';
import { ModalHeaderComponent } from './modal-header.component';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [ModalComponent, ModalHeaderComponent, ModalBodyComponent, ModalFooterComponent, ModalCloseComponent],
  imports: [CommonModule, RouterModule],
  exports: [ModalComponent, ModalHeaderComponent, ModalBodyComponent, ModalFooterComponent, ModalCloseComponent],
  providers: [],
})
export class ModalModule {}
