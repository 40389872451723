<div [ngClass]="isCorrect ? '' : 'red-border'">
    <div class="dc-ace-editor" #aceEditor [ngClass]="{ extended: aceEditorExtended }"></div>
</div>
<div class="mt-2 flex">
    <div class="toggle-btn ml-auto">
        <p-toggleButton
            onLabel="Zmenšit"
            offLabel="Zvětšit"
            onIcon="fa fa-chevron-up"
            offIcon="fa fa-chevron-down"
            iconPos="left"
            styleClass="p-button-primary"
            [(ngModel)]="aceEditorExtended"
        ></p-toggleButton>
    </div>
</div>
