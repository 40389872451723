import { DefaultUrlSerializer, UrlTree } from '@angular/router';

export class NcUrlSerializer extends DefaultUrlSerializer {
  private readonly delimiter = ';;';
  private readonly originalDelimiter = '//';

  public override parse(url: string): UrlTree {
    const reversedUrl = this.reverseUrl(url);
    return super.parse(reversedUrl);
  }

  public override serialize(tree: UrlTree): string {
    const url = super.serialize(tree);
    return this.convertUrl(url);
  }

  public reverseUrl(url: string): string {
    return this.replaceAuxiliaryUrlDelimiter(this.originalDelimiter, url);
  }

  public convertUrl(url: string): string {
    return this.replaceAuxiliaryUrlDelimiter(this.delimiter, url);
  }

  private replaceAuxiliaryUrlDelimiter(delimiter: string, url: string): string {
    const auxiliaryPathRegex = /\(([^)]+)\)/;
    const auxiliaryPaths = url.match(auxiliaryPathRegex);

    if (auxiliaryPaths && auxiliaryPaths[1]) {
      const pathWithinParentheses = auxiliaryPaths[1];
      const replacedDelimiterPart = pathWithinParentheses.replace(/(;{2})|(\/{2})/g, delimiter);
      return url.replace(pathWithinParentheses, replacedDelimiterPart);
    }

    return url;
  }
}
