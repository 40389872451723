<p-calendar
    #calendar
    appendTo="body"
    [defaultDate]="preselectedDate"
    [inputId]="inputId"
    class="flex w-full"
    [name]="name"
    [selectionMode]="mode"
    [placeholder]="placeholder"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [numberOfMonths]="months"
    [readonlyInput]="readonly"
    [showOtherMonths]="showOtherMonths"
    [showTime]="displayTime"
    [showClear]="showClear"
    [disabled]="isDisabled"
    [showIcon]="true"
    [keepInvalid]="true"
    [selectOtherMonths]="selectOtherMonths"
    [styleClass]="'ui-calendar w-full ' + styleClass"
    [panelStyle]="panelStyle"
    [panelStyleClass]="'ui-calendar-datepicker ' + panelStyleClass"
    (onSelect)="onSelect(calendar)"
    (onClose)="onClose(calendar)"
    (onClear)="onClear(calendar)"
    (onInput)="onManualInput($event)"
    (onMonthChange)="onMonthYearChange()"
    (onYearChange)="onMonthYearChange()"
    (onBlur)="onBlur(calendar)"
>
    <ng-template pTemplate="triggericon">
        <ui-svg icon="Calendar" strokeColor="stroke-gray-400" />
    </ng-template>

    <p-footer *ngIf="showPresets">
        <ng-container *ngIf="mode === selectionMode.Single && minDate < today">
            <ui-button
                label="Minulý týden"
                (onClick)="applyPreset(-1, 'week')"
                styleClass="p-button-secondary p-button-text"
            />
            <ui-button
                label="Minulý měsíc"
                (onClick)="applyPreset(-1, 'month')"
                styleClass="p-button-secondary p-button-text"
            />
            <ui-button
                label="Minulý rok"
                (onClick)="applyPreset(-1, 'year')"
                styleClass="p-button-secondary p-button-text"
            />
        </ng-container>
        <ng-container *ngIf="mode === selectionMode.Single && today < maxDate">
            <ui-button label="Dnes" (onClick)="applyPreset(0, 'day')" styleClass="p-button-secondary p-button-text" />
            <ui-button label="Včera" (onClick)="applyPreset(-1, 'day')" styleClass="p-button-secondary p-button-text" />
            <ui-button
                label="Před týdnem"
                (onClick)="applyPreset(-1, 'week')"
                styleClass="p-button-secondary p-button-text"
            />
            <ui-button
                label="Minulý měsíc"
                (onClick)="applyPreset(-1, 'month')"
                styleClass="p-button-secondary p-button-text"
            />
            <ui-button
                label="Minulý rok"
                (onClick)="applyPreset(-1, 'year')"
                styleClass="p-button-secondary p-button-text"
            />
        </ng-container>
        <ng-container *ngIf="mode === selectionMode.Single && minDate > today">
            <ui-button label="Dnes" (onClick)="applyPreset(0, 'day')" styleClass="p-button-secondary p-button-text" />
            <ui-button label="Zítra" (onClick)="applyPreset(1, 'day')" styleClass="p-button-secondary p-button-text" />
            <ui-button
                label="Za týden"
                (onClick)="applyPreset(1, 'week')"
                styleClass="p-button-secondary p-button-text"
            />
            <ui-button
                label="Za měsíc"
                (onClick)="applyPreset(1, 'month')"
                styleClass="p-button-secondary p-button-text"
            />
            <ui-button
                label="Za rok"
                (onClick)="applyPreset(1, 'year')"
                styleClass="p-button-secondary p-button-text"
            />
        </ng-container>
        <ng-container *ngIf="mode === selectionMode.Range">
            <ui-button
                label="Dnes - celý týden"
                (onClick)="applyPreset(0, 'week', false)"
                styleClass="p-button-secondary p-button-text"
            />
            <ui-button
                label="Dnes - celý měsíc"
                (onClick)="applyPreset(0, 'month', false)"
                styleClass="p-button-secondary p-button-text"
            />
        </ng-container>
    </p-footer>
    <ng-container *ngIf="showTimeToggle">
        <ng-template pTemplate="footer">
            <div *ngIf="showPresets" class="preset-footer"></div>
            <div class="time-toggle">
                <div class="flex justify-end">
                    <div class="m-2.5 inline-grid grid-cols-[minmax(20px,_min-content)_auto] items-center gap-2">
                        <label for="timeToggle" class="order-2 inline-flex">Zadat čas</label>
                        <p-inputSwitch
                            class="order-1 inline-flex"
                            inputId="timeToggle"
                            [(ngModel)]="displayTime"
                            (onChange)="showTimePicker($event.checked)"
                        ></p-inputSwitch>
                    </div>
                </div>
            </div>
            <div class="time-footer"></div>
        </ng-template>
    </ng-container>
</p-calendar>
<ui-validation-error><ng-content></ng-content></ui-validation-error>
