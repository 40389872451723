import { isNonNullObject } from './common-utils';

export function getCurrentDateISO() {
  return new Date().toISOString().substring(0, 10);
}

export type MaybeDateObj = DateObj | null;
export type MaybeTimeObj = TimeObj | null;
export type MaybeDateTimeObj = DateTimeObj | null;

export interface DateObj {
  year: number;
  month: number;
  day: number;
}

export interface TimeObj {
  hours: number;
  minutes: number;
}

export interface DateTimeObj extends DateObj, TimeObj {}

export interface CalendarDate {
  value: number;
  isCurrent: boolean;
  isWeekend: boolean;
}

export function cmpDateObjEquality(a: MaybeDateObj, b: MaybeDateObj): boolean {
  if ((a === b) === null) return true;
  if (a === null || b === null) return false;
  return a.day === b.day && a.month === b.month && a.year === b.year;
}

export function cmpTimeObjEquality(a: MaybeTimeObj, b: MaybeTimeObj): boolean {
  if ((a === b) === null) return true;
  if (a === null || b === null) return false;
  return a.hours === b.hours && a.minutes === b.minutes;
}

export function isValidDateObject(val: any): boolean {
  return (
    isNonNullObject(val) && typeof val.year === 'number' && typeof val.month === 'number' && typeof val.day === 'number'
  );
}

export function isValidDateTimeObject(val: any): boolean {
  return isValidDateObject(val) && typeof val.hours === 'number' && typeof val.minutes === 'number';
}

export function getCalendarDaysInMonth(_month: number, year: number): CalendarDate[] {
  const days: CalendarDate[] = [];
  const month = _month - 1;
  const date = new Date(year, month, 0);
  date.setDate(date.getDate() - date.getDay() + 1);
  for (let i = 0; i < 6 * 7; i++) {
    const day = date.getDay();
    days.push({
      value: date.getDate(),
      isCurrent: date.getMonth() === month,
      isWeekend: day >= 5,
    });
    date.setDate(date.getDate() + 1);
  }
  return days;
}

export function getNowTimeObj(): TimeObj {
  const now = new Date();
  return {
    hours: now.getHours(),
    minutes: now.getMinutes(),
  };
}

export function getNowDateObj(): DateObj {
  const now = new Date();
  return {
    year: now.getFullYear(),
    month: now.getMonth() + 1,
    day: now.getDate(),
  };
}

export function getNowDateTimeObj(): DateTimeObj {
  return { ...getNowDateObj(), ...getNowTimeObj() };
}

export function getNowDateTimeISO(): string {
  const a = new Date();
  return [[getYear(a), getMonth(a, 1), getDay(a)].join('-'), [getHours(a), getMinutes(a)].join(':')].join('T');
}

export function getTomorrowDateTimeISO(): string {
  const a = new Date();
  const tomorrow = new Date(a.setDate(a.getDate() + 1));
  return [
    [getYear(tomorrow), getMonth(tomorrow, 1), getDay(tomorrow)].join('-'),
    [getHours(tomorrow), getMinutes(tomorrow)].join(':'),
  ].join('T');
}

export function addDays(date: Date, days: number): Date {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function minusOneDay(date: Date = new Date()) {
  return addDays(date, -1);
}

export function getCurrentDateWithoutTime(): Date {
  let d = new Date();
  d.setHours(0, 0, 0, 0);
  return d;
}

export function getLastDayOfLastMonth(): Date {
  let d = getCurrentDateWithoutTime();
  d.setDate(1);
  d.setHours(-1);
  return d;
}

export function getFirstDayOfLastMonth(): Date {
  let d = getFirstDayOfActualMonth();
  d.setMonth(d.getMonth() - 1);
  return d;
}

export function getFirstDayOfActualMonth(): Date {
  let d = getCurrentDateWithoutTime();
  d.setDate(1);
  d.setMonth(d.getMonth());
  return d;
}

function getYear(d: Date) {
  return d.getFullYear().toString();
}

function getMonth(d: Date, diff: number = 0) {
  return getPaddedString(d.getMonth() + diff);
}

function getDay(d: Date, diff: number = 0) {
  return getPaddedString(d.getDate() + diff);
}

function getHours(d: Date) {
  return getPaddedString(d.getHours());
}

function getMinutes(d: Date) {
  return getPaddedString(d.getMinutes());
}

function getPaddedString(n: number) {
  return n.toString().padStart(2, '0');
}
