import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  DateTimeObj,
  MaybeDateTimeObj,
  cmpDateObjEquality,
  cmpTimeObjEquality,
  parseISODate,
  parseISODateTime,
  parseUserEntryDate,
  parseUserEntryTime,
} from '@utils';

@Component({
  selector: 'ui-date-time-field-legacy',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `<div class="ui-date-time-field-legacy__inputs ai--baseline">
      <div class="d--flex fw--nowrap jc--space-between">
        <div>
          <input
            class="c-form-control"
            type="text"
            [disabled]="disabled"
            [value]="dateValue"
            [placeholder]="datePlaceholder"
            (keyup)="dateValueChange($event)"
          />
        </div>
        <input
          class="c-form-control ml--1-2"
          type="text"
          [disabled]="disabled"
          [value]="timeValue"
          (keyup)="timeValueChange($event)"
          *ngIf="showTime"
        />
      </div>
      <div class="ui-date-time-field-legacy__inputs--button-wrapp" [ngClass]="{ '--disabled': disabled }">
        <a class="dc-btn dc-btn--full-faced" *ngIf="showPicker && !this.isPickerVisible" (click)="handleTogglePicker()">
          <i class="fa fa-fw fa-calendar"></i>
        </a>
      </div>
    </div>
    <div class="dc-form__validation --failure" *ngIf="!isDateValid && showDateValid">Neplatný formát datumu</div>
    <div class="dc-form__validation --failure" *ngIf="!isTimeValid">Neplatný formát času</div>
    <ui-date-time-picker-legacy
      [value]="dateTimeObj"
      (pick)="handlePick($event)"
      (close)="handleTogglePicker()"
      [ngClass]="{ 'one-error': !isDateValid, 'two-error': !isTimeValid }"
      *ngIf="isPickerVisible"
      [showTimePicker]="showTime"
      [showTodayBtn]="showTodayButton"
    />`,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimeFieldComponent),
      multi: true,
    },
  ],
})
export class DateTimeFieldComponent implements ControlValueAccessor {
  @HostBinding('class') classList = 'd--block dc-native-form__date-time-field';

  @Input() formControlName!: string;
  @Input() showPicker = false;
  @Input() showTime = true;
  @Input() showDateValid = true;
  @Input() showTodayButton = true;
  @Input() disabled = false;
  @Input() datePlaceholder = '';
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('dateTimeValue') _dateTimeValue!: string;

  isValid = true;
  isDateValid = true;
  isTimeValid = true;
  isPickerVisible = false;
  dateTimeObj!: DateTimeObj;
  dateValue = '';
  timeValue = '';

  propagateChange: any = () => {};

  set dateTimeValue(val: string | null) {
    this._dateTimeValue = val ?? '';
    this.dateTimeObj = this.parseInput(val) as DateTimeObj;
    if (!this.dateTimeObj) {
      this.dateTimeObj = this.parseStrDate(val ?? '') as DateTimeObj;
    }
    this.dateValue = this.formatDateObj(this.dateTimeObj);
    this.timeValue = this.formatTimeObj(this.dateTimeObj);
    this.propagateChange(val);
    this.ref.markForCheck();
  }

  constructor(private ref: ChangeDetectorRef) {}

  dateValueChange($event: Event) {
    const val = ($event.target as HTMLInputElement)?.value;
    const parsed = this.parseUserDateInput(val);
    this.isDateValid = parsed !== null;
    if (!cmpDateObjEquality(this.dateTimeObj, parsed)) {
      this.dateTimeObj = parsed as DateTimeObj;
      this.propagateChange(this.formatOutput(this.dateTimeObj));
    }
  }

  timeValueChange($event: Event) {
    const val = ($event.target as HTMLInputElement)?.value;
    const parsed = this.parseUserTimeInput(val);
    this.isTimeValid = parsed !== null;
    if (!cmpTimeObjEquality(this.dateTimeObj, parsed)) {
      Object.assign(this.dateTimeObj as Object, parsed);
      this.propagateChange(this.formatOutput(this.dateTimeObj));
    }
  }

  writeValue(dateTime: any): void {
    if (dateTime !== undefined) {
      this.dateTimeValue = dateTime;
    }
  }

  registerOnChange(fn: () => void): void {
    this.propagateChange = fn;
  }

  registerOnTouched(): void {}

  handlePick(value: DateTimeObj): void {
    this.dateValue = this.formatDateObj(value);
    this.timeValue = this.formatTimeObj(value);
    this.propagateChange(this.formatOutput(value));
    this.ref.markForCheck();
  }

  handleTogglePicker(): void {
    this.isPickerVisible = !this.isPickerVisible;
    this.ref.markForCheck();
  }

  private parseInput(val: string | null): MaybeDateTimeObj {
    if (val === null || typeof val !== 'string') return null;
    let parsed = parseISODateTime.exec(val);
    if (parsed && parsed.length === 7) {
      parsed.shift();
      const [year, month, day, hours, minutes] = parsed.map((i) => parseInt(i, 10));
      return { year, month, day, hours, minutes };
    } else {
      parsed = parseISODate.exec(val);
      if (parsed === null || parsed.length !== 4) return null;
      parsed.shift();
      const [year, month, day] = parsed.map((i) => parseInt(i, 10));
      const [hours, minutes] = [0, 0];
      return { year, month, day, hours, minutes };
    }
  }

  private formatOutput(val: MaybeDateTimeObj) {
    if (val === null) return null;
    return [
      [
        val.year.toString().padStart(4, '0'),
        val.month.toString().padStart(2, '0'),
        val.day.toString().padStart(2, '0'),
      ].join('-'),
      [val.hours.toString().padStart(2, '0'), val.minutes.toString().padStart(2, '0')].join(':'),
    ].join('T');
  }

  private formatDateObj(val: DateTimeObj): string {
    if (val === null) return '';
    return `${val.day}. ${val.month}. ${val.year}`;
  }

  private formatTimeObj(val: DateTimeObj): string {
    if (val === null) return '';
    return `${val.hours}:${val.minutes.toString().padStart(2, '0')}`;
  }

  private parseUserDateInput(val: string): MaybeDateTimeObj {
    const parsed = parseUserEntryDate.exec(val);
    if (parsed === null || parsed.length !== 4) return null;
    parsed.shift();
    const [day, month, year] = parsed.map((i) => parseInt(i, 10));
    const [hours, minutes] = [new Date().getHours(), new Date().getMinutes()];
    return { year, month, day, hours, minutes };
  }

  private parseUserTimeInput(val: string) {
    const parsed = parseUserEntryTime.exec(val);
    if (parsed === null || !parsed[2] || !parsed[3]) return null;
    parsed.shift();
    parsed.shift();
    const [hours, minutes] = parsed.map((i: string) => parseInt(i, 10));
    return { hours, minutes };
  }

  private parseStrDate(val: string) {
    if (val === null || typeof val !== 'string' || !this.isValidDate(val)) return null;
    const parsed = parseISODate.exec(val);
    if (parsed === null || parsed.length !== 4) return null;
    parsed.shift();
    const [year, month, day] = parsed.map((i) => parseInt(i, 10));
    const [hours, minutes] = [0, 0];
    return { year, month, day, hours, minutes };
  }

  private isValidDate(val: string) {
    const dateStr = new Date(val);
    return !isNaN(dateStr.getMonth());
  }
}
