import { FormControl } from '@angular/forms';

export interface Localization {
  cs: string;
  en?: string;
}

export interface LocalizationFormGroup {
  cs: FormControl<string>;
  en: FormControl<string>;
}

export enum Locales {
  CZ = 'cs',
  EN = 'en',
}
