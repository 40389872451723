import { Accordion, AccordionTabCloseEvent, AccordionTabOpenEvent } from 'primeng/accordion';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Input,
  OnInit,
  Signal,
  computed,
  inject,
  input,
  output,
  signal,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { CommonMessages } from '@domain';
import { DisplayMode } from './accordion.enum';
import { SeverityType } from '../message/message.model';
import { UserProfileService } from '@framework';

@Component({
  selector: 'ui-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionComponent implements OnInit, AfterViewInit {
  @Input() selected = false;
  @Input() disabled = false;
  @Input() header!: string;
  @Input() headerActions = false;
  @Input() loading = false;
  @Input() mode = DisplayMode.Flat;
  @Input() error!: boolean;
  @Input() displayMessage!: boolean;
  @Input() messageSeverity!: SeverityType;
  @Input() messageTitle!: string;
  @Input() cache = false;
  @Input() colorMode: 'blue' | 'gray' | 'white' = 'blue';
  @Input() contentStyleClass!: string;

  readonly accordionId = input.required<string>();

  readonly onOpen = output<AccordionTabOpenEvent>();
  readonly onClose = output<AccordionTabCloseEvent>();

  accordion = viewChild.required<Accordion>('accordion');

  private readonly destroyRef = inject(DestroyRef);
  private readonly profile = inject(UserProfileService);

  readonly displayMode = DisplayMode;
  readonly commonMessages = CommonMessages;

  openStacked!: boolean;

  isOpen: Signal<boolean> = signal<boolean>(false);

  ngOnInit(): void {
    this.openStacked = this.selected;

    this.isOpen = computed(() => {
      const opened = this.profile.getPreference(this.accordionId());
      if (opened === null || opened === undefined) {
        return this.selected;
      }
      return opened;
    });
  }

  ngAfterViewInit(): void {
    this.accordion()
      ?.tabs[0].selectedChange.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((selected) => (this.openStacked = selected));
  }

  onOpenClicked(event: AccordionTabOpenEvent): void {
    this.profile.setPreference(this.accordionId(), true);
    this.onOpen.emit(event);
  }

  onCloseClicked(event: AccordionTabCloseEvent): void {
    this.profile.setPreference(this.accordionId(), false);
    this.onClose.emit(event);
  }
}
