import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Injectable, inject } from '@angular/core';
import { UserAccount, userAccountDiOpt } from './user-account.model';

import { ApiService } from '../../api/api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { interpolate } from '@utils';
import { map } from 'rxjs/operators';

@Injectable()
export class UserAccountService extends DefaultDataService<UserAccount> {
  private readonly opt = inject(userAccountDiOpt);

  constructor(
    private api: ApiService,
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
  ) {
    super('UserAccount', http, httpUrlGenerator);
  }

  override getById(accountId: number | String): Observable<UserAccount> {
    return this.api
      .get<any>(interpolate(this.opt.getUserAccountPath, { accountId }))
      .pipe(map((res) => res.accounts[0]));
  }
}
