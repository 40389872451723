import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import dayjs from 'dayjs';

export function isDateInFutureValidator(unit: dayjs.UnitType): ValidatorFn {
  return (control: AbstractControl<Date>): ValidationErrors | null => {
    if (!control?.value) {
      return null;
    }
    const date = dayjs(control.value);

    if (!date.isValid()) {
      return { invalidDate: true };
    }
    if (date.isBefore(dayjs(), unit)) {
      return { inPast: true };
    }
    return null;
  };
}
