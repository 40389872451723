import { ChangeDetectionStrategy, Component, Input, input } from '@angular/core';

// jelly =  https://uiverse.io/G4b413l/kind-cougar-54

@Component({
  selector: 'ui-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent {
  styleClass = input<string>();
  variant = input<'default' | 'jelly' | 'ring'>('jelly');
  message = input<string>();
}
