import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';

import { ApiModule } from '../api/api.module';
import { CommonModule } from '@angular/common';
import { FeatureFlagsModule } from '../feature-flags/feature-flags.module';
import { LocalStorageModule } from '../local-storage/local-storage.module';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UserAPIService } from './user-api.service';
import { UserProfileService } from './user-profile/user-profile.service';
import { userOptionsDI } from './user.di';
import { userOptionsValue } from './user.opt';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ApiModule,
    ReactiveFormsModule,
    HttpClientJsonpModule,
    HttpClientModule,
    LocalStorageModule,
    FeatureFlagsModule,
  ],
  exports: [],
  providers: [UserProfileService, UserAPIService, { provide: userOptionsDI, useValue: userOptionsValue }],
})
export class UserModule {}
