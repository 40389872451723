import { ApiErrorLogger } from './implementations/api-error-logger';
import { ConsoleLogger } from './implementations/console-logger';
import { FeatureFlagsService } from '../feature-flags/feature-flags.service';
import { LOGGER_CONFIGURATION } from './tokens/logger-configuration.token';
import { LoggerConfiguration } from './models/logger-configuration.model';
import { LoggerService } from './logger.service';
import { NgModule } from '@angular/core';

@NgModule({
  providers: [
    {
      provide: LoggerService,
      deps: [FeatureFlagsService, LOGGER_CONFIGURATION],
      useFactory: (ffService: FeatureFlagsService, loggerConfiguration: LoggerConfiguration): LoggerService => {
        const consoleLogger = new ConsoleLogger();
        const apiErrorLogger = new ApiErrorLogger();

        consoleLogger.setNextLogger(apiErrorLogger);

        ffService.hasEnabled$('INS-14873-error-handler-logger').subscribe((ffEnabled) => {
          apiErrorLogger.enabled = (ffEnabled && loggerConfiguration.enabled) ?? false;
        });

        return new LoggerService(consoleLogger);
      },
    },
  ],
})
export class LoggerModule {}
