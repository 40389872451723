import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';

import { Injectable } from '@angular/core';
import { LocationStrategy } from '@angular/common';
import { getBaseUrl } from '@utils';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard {
  constructor(
    router: Router,
    protected readonly keycloak: KeycloakService,
    private readonly location: LocationStrategy,
  ) {
    super(router, keycloak);
  }

  async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    if (!this.authenticated) {
      await this.keycloak.login({
        redirectUri: getBaseUrl(this.location) + state.url,
      });
    }

    // Get the roles required from the route.
    const requiredRoles = route.data['roles'];

    let granted: boolean;

    // Allow the user to to proceed if no additional roles are required to access the route.
    if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
      granted = true;
      return granted;
    }

    // Allow the user to proceed if all the required roles are present.
    granted = requiredRoles.every((role) => this.roles.includes(role));

    // Routing user into permission denied view if don't have necessary roles.
    if (!granted) {
      await this.router.navigate(['permission-denied']);
    }

    return granted;
  }
}
