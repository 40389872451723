import { FileNameBuilder, FileType } from './file.model';

export type FileKey = string;

export interface DownloadFileConfig {
  mime: MimeType;
  fileName: string;
}

export enum MimeType {
  OpenXmlFormat = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  Plain = 'text/plain',
  ApplicationPdf = 'application/pdf',
  ImageJpeg = 'image/jpeg',
  ImagePng = 'image/png',
  ApplicationPng = 'image/png',
  Unknown = 'unknown',
}

export const utf8Charset = 'charset=UTF-8';

export interface DownloadConfig {
  config?: DownloadFileConfig;
  callbackFilter?: any;
}

export class DownloadFileConfigFactory {
  private static mimeConf: { type: FileType; mime: MimeType }[] = [
    { type: FileType.Excel, mime: MimeType.OpenXmlFormat },
    { type: FileType.PDF, mime: MimeType.Plain },
    { type: FileType.IKM, mime: MimeType.Plain },
  ];

  static of(name: string, type: FileType): DownloadFileConfig {
    return {
      mime: this.mimeConf?.find((c) => c.type === type)?.mime ?? MimeType.Unknown,
      fileName: FileNameBuilder.create(name, type),
    };
  }

  static excel(name: string): DownloadFileConfig {
    return this.of(name, FileType.Excel);
  }
  static pdf(name: string): DownloadFileConfig {
    return this.of(name, FileType.PDF);
  }
}
