import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CommonModule } from '@angular/common';
import { InputSwitchModule } from 'primeng/inputswitch';
import { NgModule } from '@angular/core';
import { SwitchComponent } from './switch.component';
import { SwitchGroupComponent } from './switch-group.component';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  declarations: [SwitchComponent, SwitchGroupComponent],
  imports: [CommonModule, TooltipModule, ReactiveFormsModule, InputSwitchModule, FormsModule],
  exports: [SwitchComponent, SwitchGroupComponent],
})
export class SwitchModule {}
