import { AfterContentInit, DestroyRef, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { ApiService, BinaryResponse } from '@framework';
import { setImage } from '@utils';

import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Directive({
  selector: `[uiSecuredImage]`,
})
export class SecuredImageDirective implements AfterContentInit, OnDestroy {
  @Input('uiSecuredImage') set path(path: string) {
    this._path = path;
    this.init();
  }

  @Input() loadingImage!: string;
  @Input() defaultImage!: string;
  @Input() errorImage!: string;
  @Input() imageWidth!: number;
  @Input() inline = false;

  private _path!: string;
  private scrollSub!: Subscription;
  private defaultWidth = 200;
  private isObserver!: IntersectionObserver;

  constructor(
    private element: ElementRef,
    private api: ApiService,
    private destroyRef: DestroyRef,
  ) {}

  ngAfterContentInit(): void {
    this.init();
  }

  ngOnDestroy(): void {
    this.scrollSub && this.scrollSub.unsubscribe();
  }

  private init(): void {
    this.setDefaultImage();

    this.isObserver = new IntersectionObserver((entries) => {
      this.checkForIntersection(entries);
    }, {});
    this.isObserver.observe(this.element.nativeElement as Element);
  }

  private checkForIntersection = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry: IntersectionObserverEntry) => {
      if (this.checkIfIntersecting(entry)) {
        const width = this.imageWidth || this.defaultWidth;

        this.api
          .fetchBinary(SecuredImageDirective.createPath(this._path, width, this.inline), 'image/png')
          .pipe(
            tap(() => this.setLoadingImage()),
            takeUntilDestroyed(this.destroyRef),
          )
          .subscribe((image: BinaryResponse) => {
            if (image) {
              this.setImage(image);
            }
          });
        this.isObserver.unobserve(this.element.nativeElement as Element);
        this.isObserver.disconnect();
      }
    });
  };

  private setImage(image: BinaryResponse): void {
    if (image?.blob) {
      setImage(this.element.nativeElement, window.URL.createObjectURL(image.blob));
    }
  }

  private checkIfIntersecting(entry: IntersectionObserverEntry) {
    return (entry as any).isIntersecting && entry.target === this.element.nativeElement;
  }

  private setDefaultImage(): void {
    this.defaultImage && setImage(this.element.nativeElement, this.defaultImage);
  }

  private setLoadingImage(): void {
    this.loadingImage && setImage(this.element.nativeElement, this.loadingImage);
  }

  private static createPath(id: string, width: number, inline: boolean): string {
    return inline
      ? `/api/latest/rest/core/document/download/${id}?type=inline`
      : `/api/latest/rest/core/v2/document/preview/${id}?width=${width}`;
  }
}
