export enum DateTimeFormat {
  Date = 'dd.MM.yyyy',
  DateDayJS = 'DD.MM.YYYY',
  DateShortDayJS = 'D.M.YYYY',
  DateTimeFullHoursDayJS = 'DD.MM.YYYY HH:mm',
  DateTimeShortHoursDayJS = 'DD.MM.YYYY H:mm',
  DateTimeShortFullHoursDayJS = 'D.M.YYYY HH:mm',
  DateTimeShortShortHoursDayJS = 'D.M.YYYY H:mm',
  DateTimeISODayJS = 'YYYY-MM-DDTHH:mm',
  DatePrimeNG = 'dd.mm.yy', // Used as default PrimeNG format (i.e. used by calendar).
  DateISO = 'YYYY-MM-DD',
  DateTime = 'dd.MM.yyyy HH:mm',
  DateTimeISO = 'YYYY-MM-ddTHH:mm',
  DateTimeSeconds = 'dd.MM.yyyy HH:mm:ss',
  DateTimeReversed = 'HH:mm dd.MM.yyyy',
  Time = 'HH:mm',
  TimeSeconds = 'H:mm:ss',
}
