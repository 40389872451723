import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { ENVIRONMENT, ToastMessage, ToastMessageType } from '@abstractions';

@Component({
  selector: 'ui-toast-content',
  templateUrl: './toast-content.component.html',
  styleUrls: ['./toast-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastContentComponent {
  readonly envConf = inject(ENVIRONMENT);
  protected readonly messageType = ToastMessageType;

  @Input() message!: ToastMessage;
  @Input() textCopied = false;

  @Output() private readonly onCopyText = new EventEmitter<void>();
  @Output() private readonly onCopyPhone = new EventEmitter<void>();
  @Output() private readonly onCopyPhoneAndRedirect = new EventEmitter<void>();

  copyPhoneAndRedirect(): void {
    this.onCopyPhoneAndRedirect.emit();
  }

  copyPhone(): void {
    this.onCopyPhone.emit();
  }

  copyText(): void {
    this.onCopyText.emit();
  }
}
