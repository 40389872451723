import { CompatClient, Stomp, StompHeaders } from '@stomp/stompjs';
import { Inject, Injectable } from '@angular/core';

import { UserService } from '../user/user.service';
import { WebsocketOptions } from './websocket.opt';
import { websocketOptionsDI } from './websocket.di';

@Injectable()
export class WebsocketService {
  public client!: CompatClient;

  private readonly reconnectDelay = 30000; // 30s
  private connectHeaders!: { Authorization: string };

  constructor(
    private user: UserService,
    @Inject(websocketOptionsDI) private opt: WebsocketOptions,
  ) {}

  connect() {
    const _connectUrl = this.opt.connect;
    const client = Stomp.over(function () {
      return new WebSocket(_connectUrl);
    });
    client.debug = () => {};
    client.reconnect_delay = this.reconnectDelay;
    client.beforeConnect = (): Promise<void> => {
      return new Promise<void>((resolve, reject) => {
        client.connectHeaders = {
          Authorization: `Bearer ${this.user.snapshot.accessToken}`,
        };
        resolve();
      });
    };
    this.client = client;
  }

  activate(connectCallback: () => void, closeCallback: () => void) {
    this.activateWithHeaders({}, connectCallback, closeCallback);
  }

  activateWithHeaders(headers: StompHeaders, connectCallback: () => void, closeCallback: () => void) {
    let reqHeaders = { ...this.connectHeaders, ...headers };
    this.client.connect(reqHeaders, connectCallback, () => {}, closeCallback);
  }
}
