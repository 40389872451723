import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PillComponent } from './pill.component';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  imports: [CommonModule, TooltipModule],
  declarations: [PillComponent],
  exports: [PillComponent],
})
export class PillModule {}
