import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { DateTimeObj, createId } from '@utils';

import { DateTimePickerInstanceState } from './date-time-picker.model';
import { DateTimePickerService } from './date-time-picker.service';

@Component({
  selector: 'ui-date-time-picker-legacy',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './date-time-picker.component.html',
  host: {
    class: 'dc-datepicker',
  },
})
export class DateTimePickerComponent implements OnInit {
  @Input() value!: DateTimeObj;
  @Input() showTimePicker = true;
  @Input() showTodayBtn = true;
  @Output() pick: EventEmitter<DateTimeObj> = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close: EventEmitter<null> = new EventEmitter();

  instanceId: string;
  state!: DateTimePickerInstanceState;
  months!: string[];

  current!: {
    year: number;
    month: number;
    day: number;
  };

  constructor(
    private dateTimePicker: DateTimePickerService,
    private ref: ChangeDetectorRef,
  ) {
    this.instanceId = createId();
  }

  ngOnInit() {
    this.dateTimePicker.initInstance(this.instanceId, this.value);

    this.dateTimePicker.getState$(this.instanceId).subscribe((state) => {
      this.state = state;
      this.ref.markForCheck();
    });

    // TODO unsubscribe
    this.dateTimePicker.getValueChanges$(this.instanceId).subscribe(() => {
      this.pick.next(this.state.value as DateTimeObj);
    });

    this.months = [
      'Leden',
      'Únor',
      'Březen',
      'Duben',
      'Květen',
      'Červen',
      'Červenec',
      'Srpen',
      'Září',
      'Říjen',
      'Listopad',
      'Prosinec',
    ];
  }

  handleSelectDay(day: any): void {
    if (!day.isCurrent) return;
    this.dateTimePicker.selectDay(this.instanceId, day);
  }

  handleSelectMonth(event: any): void {
    this.dateTimePicker.selectMonth(this.instanceId, event.target.value);
  }

  handleSelectYear(event: any): void {
    this.dateTimePicker.selectYear(this.instanceId, event.target.value);
  }

  handleSelectHour(event: any): void {
    this.dateTimePicker.selectHour(this.instanceId, event.target.value);
  }

  handleSelectMinute(event: any): void {
    this.dateTimePicker.selectMinute(this.instanceId, event.target.value);
  }

  handleClose(): void {
    this.close.next(null);
  }

  handleSelectToday(): void {
    this.dateTimePicker.setNowDateTime(this.instanceId);
  }

  handleNextMonth(): void {
    this.dateTimePicker.selectNextMonth(this.instanceId);
  }

  handlePreviousMonth(): void {
    this.dateTimePicker.selectPreviousMonth(this.instanceId);
  }
}
