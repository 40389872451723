import { ApiService } from './api.service';
import { ApiSessionService } from './api-session.service';
import { LocalStorageModule } from '../local-storage/local-storage.module';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [],
  imports: [LocalStorageModule],
  exports: [],
  providers: [ApiService, ApiSessionService],
})
export class ApiModule {}
