<div class="ui-toast-buttons">
    <ng-container *ngFor="let button of toastButtons">
        <ui-button
            *ngIf="button.enabled && message.data?.code === button.type"
            [label]="button.label"
            (onClick)="button.action(message)"
            styleClass="p-button-text p-button-secondary"
        />
        <p-divider></p-divider>
    </ng-container>
</div>
