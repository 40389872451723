import { AddressApiService, AddressComponent, AddressService } from './address';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BaseElementsModule } from '../base-elements/base-elements.module';
import { CheckboxFieldDirective } from './checkbox-field.directive';
import { CommonModule } from '@angular/common';
import { DateFieldComponent } from './date-time/date-field.component';
import { DateTimeFieldComponent } from './date-time/date-time-field.component';
import { DateTimePickerComponent } from './date-time/date-time-picker.component';
import { DateTimePickerService } from './date-time/date-time-picker.service';
import { FormElementCheckboxComponent } from './form-element-checkbox.component';
import { FormElementComponent } from './form-element.component';
import { FormFieldMessageDirective } from './form-field-message.directive';
import { FormFieldMessagesDirective } from './form-field-messages.directive';
import { FormGroupDirective } from './form-group.directive';
import { FormLabelDirective } from './form-label.directive';
import { FormStatusComponent } from './form-status.component';
import { GroupElementComponent } from './group-element.component';
import { GroupFieldComponent } from './group-field.component';
import { InputFieldDirective } from './input-field.directive';
import { LookupFieldComponent } from './lookup-field.component';
import { NgModule } from '@angular/core';
import { PartnerNameComponent } from './partner-name';
import { RadioFieldDirective } from './radio-field.directive';
import { SecuredImagesModule } from '../../../directives/secured-images/secured-images.module';
import { SelectFieldDirective } from './select-field.directive';
import { TimeFieldComponent } from './date-time/time-field.component';

const componentsAndDirectives = [
  InputFieldDirective,
  CheckboxFieldDirective,
  LookupFieldComponent,
  FormElementComponent,
  FormLabelDirective,
  FormFieldMessageDirective,
  FormFieldMessagesDirective,
  FormStatusComponent,
  FormGroupDirective,
  FormElementCheckboxComponent,
  DateFieldComponent,
  DateTimeFieldComponent,
  TimeFieldComponent,
  SelectFieldDirective,
  RadioFieldDirective,
  GroupFieldComponent,
  GroupElementComponent,
  DateTimePickerComponent,
  AddressComponent,
  PartnerNameComponent,
];

@NgModule({
  declarations: componentsAndDirectives,
  imports: [CommonModule, BaseElementsModule, FormsModule, ReactiveFormsModule, SecuredImagesModule],
  providers: [DateTimePickerService, AddressService, AddressApiService],
  exports: componentsAndDirectives,
})
export class NativeFormsModule {}
