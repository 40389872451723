import { Account, User, UserOptions } from './model/user.model';
import { Inject, Injectable } from '@angular/core';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { Observable, from, merge, of, shareReplay, switchMap } from 'rxjs';
import { combineLatestWith, distinctUntilChanged, filter, map } from 'rxjs/operators';

import { LocalStorageService } from '../local-storage/local-storage.service';
import { LoggerService } from '../logger/logger.service';
import { UserAPIService } from './user-api.service';
import { userOptionsDI } from './user.di';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  user$: Observable<any>;
  snapshot!: User; // todo: @deprecated

  constructor(
    @Inject(userOptionsDI) private opt: UserOptions,
    public api: UserAPIService,
    protected localStorage: LocalStorageService,
    protected keycloak: KeycloakService,
    protected readonly logger: LoggerService,
  ) {
    this.user$ = merge(
      this.keycloak.getToken(),
      this.keycloak.keycloakEvents$.asObservable().pipe(
        map((e) => e.type),
        filter((e) => e === KeycloakEventType.OnAuthRefreshSuccess),
      ),
    ).pipe(
      switchMap((e) => from(this.keycloak.getToken())),
      map((e) => this.getUserFromToken(e)),
      distinctUntilChanged(),
      shareReplay(),
    );

    this.user$.subscribe((user) => {
      this.localStorage.setProperty('user', user);
      this.snapshot = user;
    });

    this.initApiKey();
  }

  protected initApiKey(): void {
    this.user$.pipe(combineLatestWith(this.api.getApiKey())).subscribe(([_, apiKey]) => {
      this.localStorage.setRawProperty('ngStorage-apiKey', apiKey);
    });
  }

  isLogged$(): Observable<boolean> {
    return of(this.keycloak.isLoggedIn());
  }

  private getUserFromToken(token: string): any {
    if (!token) {
      return null;
    }
    return {
      ...UserAPIService.doDecodeUserResponseByToken({
        accessToken: token,
        refreshToken: this.keycloak.getKeycloakInstance().refreshToken,
      }),
    };
  }

  getAccounts(): Observable<Account[]> {
    return this.api.getAccounts();
  }
}
