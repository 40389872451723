import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import dayjs from 'dayjs';

export function isBeforeDateValidator(firstDate: string, secondDate: string, unit: dayjs.UnitType): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const firstDateControl = control?.get(firstDate);
    const secondDateControl = control?.get(secondDate);
    if (!firstDateControl?.value || !secondDateControl?.value) {
      return null;
    }
    return dayjs(firstDateControl.value).isBefore(dayjs(secondDateControl.value), unit) ? null : { dateIsAfter: true };
  };
}

export function isBeforeSpecificDateValidator(date: Date, unit: dayjs.UnitType): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control?.value) {
      return null;
    }
    const value = dayjs(control.value);
    if (!value.isValid()) {
      return { invalidDate: true };
    }
    return dayjs(value).isBefore(dayjs(date), unit) ? { inPast: true } : null;
  };
}
