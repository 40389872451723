<div class="relative flex flex-col items-center gap-y-4" *ngIf="toasts?.length">
    <ng-container *ngIf="toasts?.length > 3">
        <a
            (click)="closeAll(toastType.Fatal)"
            class="flex justify-center rounded-full bg-black/30 px-4 py-1 text-gray-600 opacity-0 hover:text-gray-100 hover:no-underline hover:opacity-100"
            >Skrýt všechna oznámení</a
        >
    </ng-container>
    <ui-toast *ngFor="let toast of toasts" [message]="toast" (onClose)="close($event)" @toastAnimation />
</div>
