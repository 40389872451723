import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DisplayMode, Severity } from './message.enum';
import { DisplayModeType, SeverityType } from '../../primeng/message/message.model';

import { CommonMessages } from '@domain';
import { twMerge } from 'tailwind-merge';

@Component({
  selector: 'ui-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageComponent {
  readonly commonMessages = CommonMessages;

  @Input() mode: DisplayModeType = DisplayMode.Block;
  @Input() severity: SeverityType = Severity.Info;
  @Input() title: string = this.commonMessages.CannotLoadDataRetryAndContactSupport;
  @Input() iconAlign: 'top' | 'center' = 'center';

  size: 'sm' | 'md' = 'md';

  private _styleClass?: string;

  @Input() set styleClass(classes: string) {
    this._styleClass = classes;
  }

  get styleClass(): string {
    if (this.mode === DisplayMode.Inline) {
      this._styleClass = twMerge('ui-message ui-inline-message', this._styleClass);
    } else {
      this._styleClass = twMerge('ui-message mb-4', this._styleClass);
    }

    return this._styleClass;
  }

  get iconName(): string {
    let _icon = '';
    switch (this.severity) {
      case Severity.Fatal:
        _icon = 'Danger Octagon';
        break;
      case Severity.Error:
        _icon = 'Alert Circle';
        break;
      case Severity.Warning:
        _icon = 'Alert Octagon';
        break;
      case Severity.Success:
        _icon = 'Check Circle';
        break;
      case Severity.Info:
        _icon = 'Info Circle';
        break;
      case Severity.Empty:
        _icon = 'Question Circle';
        break;
    }

    return _icon;
  }
}
