import { DestroyRef, Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { AccessControlService } from './access-control.service';
import { take } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Directive({
  selector: 'fwkPermission,[fwkPermission]',
})
export class PermissionDirective {
  inverse = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private accessControl: AccessControlService,
    private destroyRef: DestroyRef,
  ) {}

  @Input() set fwkPermission(_credential: string | [string, string]) {
    let credential;
    if (_credential && typeof _credential !== 'string') {
      credential = _credential[1];
      this.inverse = _credential[0] === 'not';
    } else {
      credential = _credential;
    }
    this.accessControl
      .hasPermission$(credential)
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe(this.updateView.bind(this));
  }

  private updateView(_visible: boolean): void {
    const visible = this.inverse ? !_visible : _visible;
    visible ? this.viewContainer.createEmbeddedView(this.templateRef) : this.viewContainer.clear();
  }
}
