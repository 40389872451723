import { createFeatureSelector, createSelector } from '@ngrx/store';

import { MergedRouteReducerState } from './merged-route';
import { routerStateConfig } from './router.module';

export const getRouterReducerState = createFeatureSelector<MergedRouteReducerState>(routerStateConfig.stateKey);
export const getMergedRoute = createSelector(getRouterReducerState, (routerReducerState) => routerReducerState.state);
export const getRouteParams = createSelector(getMergedRoute, (state) => state.params);
// trigger tohoto selectoru je vícenásobný - počet lze zredukovat pomocí distinctUntilChanged()
export const getRouteQueryParams = createSelector(getMergedRoute, (state) => state.queryParams);
export const getRouteData = createSelector(getMergedRoute, (state) => state.data);
