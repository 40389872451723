import { Attribute, Directive, ElementRef, HostBinding } from '@angular/core';

import { NgControl } from '@angular/forms';

@Directive({
  selector:
    '[uiInputFieldLegacy],' +
    'input[uiInputFieldLegacy]:not([type=checkbox]),' +
    'input[uiInputFieldLegacy]:not([type=radio])',
  exportAs: 'uiInputFieldLegacy',
})
export class InputFieldDirective {
  @HostBinding('id') id!: string;
  @HostBinding('class.c-form-control') addClass;

  constructor(
    @Attribute('formControlName') public formControlName: string,
    public formControl: NgControl,
    elem: ElementRef,
  ) {
    this.addClass = elem.nativeElement.tagName.toLowerCase().substr(0, 2) !== 'dc';
  }
}
