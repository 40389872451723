export enum FileType {
  Excel,
  PDF,
  IKM,
}

export interface FileSuffixConfig {
  getSuffix(): string;
}

export class ExcelSuffix implements FileSuffixConfig {
  getSuffix() {
    return '.xlsx';
  }
}

export class PdfSuffix implements FileSuffixConfig {
  getSuffix() {
    return '.pdf';
  }
}

export class IkmSuffix implements FileSuffixConfig {
  getSuffix() {
    return '.ikm';
  }
}

export interface FileConfig {
  type: FileType;
  suffix: FileSuffixConfig;
}

export class FileFactory {
  private static fileTypes: FileConfig[] = [
    { type: FileType.PDF, suffix: new PdfSuffix() },
    { type: FileType.Excel, suffix: new ExcelSuffix() },
    { type: FileType.IKM, suffix: new IkmSuffix() },
  ];

  static getConfig(type: FileType): FileConfig | undefined {
    return this.fileTypes.find((f) => f.type === type);
  }
}

export class FileNameBuilder {
  static create(name: string, type: FileType = FileType.Excel): string {
    return `${name}${FileFactory.getConfig(type)?.suffix.getSuffix()}`;
  }

  static excel(name: string = 'undefined'): string {
    return this.create(name);
  }

  static pdf(name: string = 'undefined'): string {
    return this.create(name, FileType.PDF);
  }
}
