import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { twMerge } from 'tailwind-merge';

@Component({
  selector: 'ui-accordion-content',
  templateUrl: './accordion-content.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionContentComponent {
  @Input() colorMode?: string;
  @Input() styleClass?: string;
  protected readonly twMerge = twMerge;
}
