import { Observable, Subject } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  private readonly handleMenuDisplaySubject$ = new Subject<void>();

  get handleMenuDisplay$(): Observable<void> {
    return this.handleMenuDisplaySubject$.asObservable();
  }

  handleMenuDisplay() {
    this.handleMenuDisplaySubject$.next();
  }
}
