import { ApiErrorInterceptor } from '../api-error.interceptor';
import { ApiPrefixInterceptor } from '../api-prefix.interceptor';
import { AuthorizationInterceptor } from '../authorization.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { apiHttpOpt } from '../../api.opt';
import { appHttpOptDI } from '../../api.di';

export const commonApiInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ApiPrefixInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ApiErrorInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthorizationInterceptor,
    multi: true,
  },
  {
    provide: appHttpOptDI,
    useValue: apiHttpOpt,
  },
];
