import { ButtonComponent } from '../../primeng/button/button.component';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { DividerModule } from 'primeng/divider';
import { FeatureFlagsModule } from '@framework';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { ToastActionsComponent } from './toast-actions/toast-actions.component';
import { ToastButtonsComponent } from './toast-buttons/toast-buttons.component';
import { ToastComponent } from './toast.component';
import { ToastContainer } from './toast.container';
import { ToastContentComponent } from './toast-content/toast-content.component';
import { ToastIconComponent } from './toast-icon/toast-icon.component';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ButtonModule,
    TooltipModule,
    DividerModule,
    FeatureFlagsModule,
    ButtonComponent,
    SvgIconModule,
  ],
  declarations: [
    ToastContainer,
    ToastIconComponent,
    ToastContentComponent,
    ToastActionsComponent,
    ToastButtonsComponent,
    ToastComponent,
  ],
  exports: [ToastContainer],
})
export class ToastModule {}
