import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import dayjs from 'dayjs';

export function isDateValidFormatValidator(): ValidatorFn {
  return (control: AbstractControl<Date>): ValidationErrors | null => {
    if (!control?.value) {
      return null;
    }
    const date = dayjs(control.value);

    if (!date.isValid()) {
      return { invalidDate: true };
    }
    return null;
  };
}
