export enum Severity {
  Empty = 'empty',
  Success = 'success',
  Warning = 'warn',
  Info = 'info',
  Error = 'error',
  Fatal = 'fatal',
}

export enum DisplayMode {
  Inline = 'inline',
  Block = 'block',
}
