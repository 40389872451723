<div
    #container
    [attr.id]="id"
    [ngClass]="containerClass"
    (click)="onContainerClick($event)"
    [ngStyle]="style"
    [class]="styleClass"
>
    <span
        #focusInput
        [ngClass]="inputClass"
        *ngIf="!editable"
        [pTooltip]="tooltip"
        [tooltipPosition]="tooltipPosition"
        [positionStyle]="tooltipPositionStyle"
        [tooltipStyleClass]="tooltipStyleClass"
        [attr.aria-disabled]="disabled"
        [attr.id]="inputId"
        role="combobox"
        [attr.aria-label]="ariaLabel || (label() === 'p-emptylabel' ? undefined : label())"
        [attr.aria-labelledby]="ariaLabelledBy"
        [attr.aria-haspopup]="'listbox'"
        [attr.aria-expanded]="overlayVisible"
        [attr.aria-controls]="id + '_list'"
        [attr.tabindex]="!disabled ? tabindex : -1"
        pAutoFocus
        [autofocus]="autofocus"
        [attr.aria-activedescendant]="focused ? focusedOptionId : undefined"
        (focus)="onInputFocus($event)"
        (blur)="onInputBlur($event)"
        (keydown)="onKeyDown($event, true)"
    >
        <ng-container *ngIf="!selectedItemTemplate; else defaultPlaceholder">{{
            label() === 'p-emptylabel' ? '&nbsp;' : label()
        }}</ng-container>
        <ng-container
            *ngIf="selectedItemTemplate && selectedOption"
            [ngTemplateOutlet]="selectedItemTemplate"
            [ngTemplateOutletContext]="{ $implicit: selectedOption }"
        ></ng-container>
        <ng-template #defaultPlaceholder>
            <span *ngIf="!selectedOption">{{ label() === 'p-emptylabel' ? '&nbsp;' : label() }}</span>
        </ng-template>
    </span>
    <input
        *ngIf="editable"
        #editableInput
        type="text"
        [attr.maxlength]="maxlength"
        [ngClass]="inputClass"
        [disabled]="disabled"
        aria-haspopup="listbox"
        [attr.placeholder]="placeholder"
        [attr.aria-expanded]="overlayVisible"
        (input)="onEditableInput($any($event))"
        (keydown)="onKeyDown($event, true)"
        (focus)="onInputFocus($event)"
        (blur)="onInputBlur($event)"
    />
    <ng-container *ngIf="loading">
        <ui-loader variant="ring" />
    </ng-container>
    <ng-container *ngIf="!loading && isVisibleClearIcon">
        <span *ngIf="!clearIconTemplate" [ngClass]="'ui-dropdown-clear-icon'">
            <ui-svg icon="X Mark" (click)="clear($event)" [attr.data-pc-section]="'clearicon'" />
        </span>
        <span
            class="p-dropdown-clear-icon"
            (click)="clear($event)"
            *ngIf="clearIconTemplate"
            [attr.data-pc-section]="'clearicon'"
        >
            <ng-template *ngTemplateOutlet="clearIconTemplate"></ng-template>
        </span>
    </ng-container>
    <div class="my-2 h-auto w-px bg-gray-200"></div>
    <div
        class="p-dropdown-trigger"
        role="button"
        aria-label="dropdown trigger"
        aria-haspopup="listbox"
        [attr.aria-expanded]="overlayVisible"
        [attr.data-pc-section]="'trigger'"
    >
        <ng-container *ngIf="!dropdownIconTemplate">
            <span class="p-dropdown-trigger-icon" *ngIf="dropdownIcon" [ngClass]="dropdownIcon"></span>
            <ui-svg icon="Chevron Down" *ngIf="!dropdownIcon" [styleClass]="'p-dropdown-trigger-icon'" />
        </ng-container>
        <span *ngIf="dropdownIconTemplate" class="p-dropdown-trigger-icon">
            <ng-template *ngTemplateOutlet="dropdownIconTemplate"></ng-template>
        </span>
    </div>

    <p-overlay
        #overlay
        [(visible)]="overlayVisible"
        [options]="overlayOptions"
        [target]="'@parent'"
        [appendTo]="appendTo"
        [autoZIndex]="autoZIndex"
        [baseZIndex]="baseZIndex"
        [showTransitionOptions]="showTransitionOptions"
        [hideTransitionOptions]="hideTransitionOptions"
        (onAnimationStart)="onOverlayAnimationStart($event)"
        (onHide)="hide()"
    >
        <ng-template pTemplate="content">
            <div [ngClass]="'p-dropdown-panel p-component'" [ngStyle]="panelStyle" [class]="panelStyleClass">
                <span
                    #firstHiddenFocusableEl
                    role="presentation"
                    [attr.aria-hidden]="true"
                    class="p-hidden-accessible p-hidden-focusable"
                    [attr.tabindex]="0"
                    (focus)="onFirstHiddenFocus($event)"
                    [attr.data-p-hidden-accessible]="true"
                    [attr.data-p-hidden-focusable]="true"
                >
                </span>
                <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
                <div class="p-dropdown-header" *ngIf="filter" (click)="$event.stopPropagation()">
                    <ng-container *ngIf="filterTemplate; else builtInFilterElement">
                        <ng-container
                            *ngTemplateOutlet="filterTemplate; context: { options: filterOptions }"
                        ></ng-container>
                    </ng-container>
                    <ng-template #builtInFilterElement>
                        <div class="p-dropdown-filter-container">
                            <input
                                #filter
                                type="text"
                                autocomplete="off"
                                [value]="_filterValue() || ''"
                                class="p-dropdown-filter p-inputtext p-component"
                                [attr.placeholder]="filterPlaceholder"
                                [attr.aria-owns]="id + '_list'"
                                (input)="onFilterInputChange($event)"
                                [attr.aria-label]="ariaFilterLabel"
                                [attr.aria-activedescendant]="focusedOptionId"
                                (keydown)="onFilterKeyDown($event)"
                                (blur)="onFilterBlur($event)"
                            />
                            <ui-svg
                                icon="Magnifier"
                                mode="fill"
                                fillColor="fill-gray-400"
                                *ngIf="!filterIconTemplate"
                                styleClass="absolute right-6 top-1/2 -mt-3"
                            />
                            <span *ngIf="filterIconTemplate" class="p-dropdown-filter-icon">
                                <ng-template *ngTemplateOutlet="filterIconTemplate"></ng-template>
                            </span>
                        </div>
                    </ng-template>
                </div>
                <div
                    class="p-dropdown-items-wrapper"
                    [style.max-height]="virtualScroll ? 'auto' : scrollHeight || 'auto'"
                >
                    <p-scroller
                        *ngIf="virtualScroll"
                        #scroller
                        [items]="visibleOptions()"
                        [style]="{ height: scrollHeight }"
                        [itemSize]="virtualScrollItemSize || _itemSize"
                        [autoSize]="true"
                        [lazy]="lazy"
                        (onLazyLoad)="onLazyLoad.emit($event)"
                        [options]="virtualScrollOptions"
                    >
                        <ng-template pTemplate="content" let-items let-scrollerOptions="options">
                            <ng-container
                                *ngTemplateOutlet="
                                    buildInItems;
                                    context: { $implicit: items, options: scrollerOptions }
                                "
                            ></ng-container>
                        </ng-template>
                        <ng-container *ngIf="loaderTemplate">
                            <ng-template pTemplate="loader" let-scrollerOptions="options">
                                <ng-container
                                    *ngTemplateOutlet="loaderTemplate; context: { options: scrollerOptions }"
                                ></ng-container>
                            </ng-template>
                        </ng-container>
                    </p-scroller>
                    <ng-container *ngIf="!virtualScroll">
                        <ng-container
                            *ngTemplateOutlet="buildInItems; context: { $implicit: visibleOptions(), options: {} }"
                        ></ng-container>
                    </ng-container>

                    <ng-template #buildInItems let-items let-scrollerOptions="options">
                        <ul
                            #items
                            [attr.id]="id + '_list'"
                            class="p-dropdown-items"
                            [ngClass]="scrollerOptions.contentStyleClass"
                            [style]="scrollerOptions.contentStyle"
                            role="listbox"
                        >
                            <ng-template ngFor let-option [ngForOf]="items" let-i="index">
                                <ng-container *ngIf="option.group">
                                    <li
                                        class="p-dropdown-item-group"
                                        [attr.id]="id + '_' + getOptionIndex(i, scrollerOptions)"
                                        [ngStyle]="{ height: scrollerOptions.itemSize + 'px' }"
                                        role="option"
                                    >
                                        <span *ngIf="!groupTemplate">{{
                                            getOptionGroupLabel(option.optionGroup)
                                        }}</span>
                                        <ng-container
                                            *ngTemplateOutlet="
                                                groupTemplate;
                                                context: { $implicit: option.optionGroup }
                                            "
                                        ></ng-container>
                                    </li>
                                </ng-container>
                                <ng-container *ngIf="!option.group">
                                    <ui-dropdown-item
                                        [id]="id + '_' + getOptionIndex(i, scrollerOptions)"
                                        [option]="option"
                                        [selected]="isSelected(option)"
                                        [label]="getOptionLabel(option)"
                                        [disabled]="isOptionDisabled(option)"
                                        [template]="itemTemplate"
                                        [focused]="focusedOptionIndex() === getOptionIndex(i, scrollerOptions)"
                                        [ariaPosInset]="getAriaPosInset(getOptionIndex(i, scrollerOptions))"
                                        [ariaSetSize]="ariaSetSize"
                                        (onClick)="onOptionSelect($event, option)"
                                        (onMouseEnter)="onOptionMouseEnter($event, getOptionIndex(i, scrollerOptions))"
                                    ></ui-dropdown-item>
                                </ng-container>
                            </ng-template>

                            <li
                                *ngIf="filterValue && isEmpty()"
                                class="p-dropdown-empty-message"
                                [ngStyle]="{ height: scrollerOptions.itemSize + 'px' }"
                            >
                                <ng-container *ngIf="!emptyFilterTemplate && !emptyTemplate; else emptyFilter">
                                    {{ emptyFilterMessageLabel }}
                                </ng-container>
                                <ng-template #emptyFilter>
                                    <ng-container
                                        *ngTemplateOutlet="emptyFilterTemplate || emptyTemplate"
                                    ></ng-container>
                                </ng-template>
                            </li>
                            <li
                                *ngIf="!filterValue && isEmpty()"
                                class="p-dropdown-empty-message"
                                [ngStyle]="{ height: scrollerOptions.itemSize + 'px' }"
                            >
                                <ng-container *ngIf="!emptyTemplate; else empty">
                                    {{ emptyMessageLabel }}
                                </ng-container>
                                <ng-template #empty>
                                    <ng-container *ngTemplateOutlet="emptyTemplate"></ng-container>
                                </ng-template>
                            </li>
                        </ul>
                    </ng-template>
                </div>
                <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
                <span
                    role="presentation"
                    [attr.aria-hidden]="true"
                    class="p-hidden-accessible p-hidden-focusable"
                    [attr.tabindex]="0"
                    (focus)="onLastHiddenFocus($event)"
                    [attr.data-p-hidden-accessible]="true"
                    [attr.data-p-hidden-focusable]="true"
                ></span>
            </div>
        </ng-template>
    </p-overlay>
</div>
