import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl, Validators } from '@angular/forms';
import { SelectionMode, SelectionModeType } from './calendar.enum';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import { Calendar } from 'primeng/calendar';
import { DateTimeFormat } from '@domain';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { dateEqualsBy } from '@utils';
import dayjs from 'dayjs';
import { fromEvent } from 'rxjs';

dayjs.extend(customParseFormat);

@Component({
  selector: 'ui-calendar',
  styleUrls: ['./calendar.components.scss'],
  templateUrl: './calendar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarComponent implements AfterViewInit, ControlValueAccessor {
  @Input() inputId!: string;
  @Input() name!: string;
  @Input() placeholder!: string;
  @Input() months = 1;
  @Input() minDate!: Date;
  @Input() maxDate!: Date;
  @Input() mode: SelectionModeType = SelectionMode.Single;
  @Input() readonly = false;
  @Input() styleClass: string = '';
  @Input() panelStyle!: any;
  @Input() panelStyleClass: string = '';
  @Input() showOtherMonths = true;
  @Input() set showTime(showTime: boolean) {
    this.displayTime = showTime;
  }
  @Input() showTimeToggle = false;
  @Input() showPresets = false;
  @Input() showClear = false;
  @Input() selectOtherMonths = true;
  @Input() selectedDate!: Date;
  @Input() selectedDateRange!: Date[];
  @Input() preselectedDate: Date = dayjs().add(1, 'minutes').toDate();

  @Output() readonly dateSelect = new EventEmitter<Calendar>();
  @Output() readonly calendarClose = new EventEmitter<Calendar>();
  @Output() readonly calendarClear = new EventEmitter<Calendar>();
  @Output() private readonly formatChange = new EventEmitter<{ displayTime: boolean }>();

  readonly today = dayjs().add(-1, 'day').endOf('day').toDate();
  readonly selectionMode = SelectionMode;
  isDisabled = false;
  displayTime = false;
  onChanged: (date: Date) => void = () => {};
  onTouched: VoidFunction = () => {};

  private comparisonUnit!: dayjs.UnitType;
  private dateFormats!: DateTimeFormat[];

  @ViewChild('calendar') calendarRef!: Calendar;

  constructor(@Self() @Optional() private control: NgControl) {
    this.control && (this.control.valueAccessor = this);
  }

  ngAfterViewInit(): void {
    if (this.selectedDate) {
      this.calendarRef.writeValue(this.selectedDate);
    }
    this.comparisonUnit = this.showTime ? 'minute' : 'day';
    this.updateDateTimeFormats();
  }

  showTimePicker(displayTime: boolean): void {
    this.displayTime = displayTime;
    this.updateDateTimeFormats();
    this.updateSize();

    let date = dayjs(this.selectedDate);
    // previous input was invalid, use calendar value
    if (!date.isValid()) {
      date = dayjs(this.calendarRef?.inputfieldViewChild?.nativeElement.value.toString(), this.dateFormats, true);
    }
    if (!displayTime) {
      date = date.startOf('day');
    }

    this.setValue(date.toDate());
    if (date.isValid()) {
      this.writeValue(date.toDate());
    }
  }

  registerOnChange(fn: (date: Date) => void): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: VoidFunction): void {
    this.onTouched = fn;
  }

  setValue(date: Date): void {
    this.onChanged(date);
    this.selectedDate = date;
  }

  writeValue(date: Date): void {
    this.calendarRef?.writeValue(date);
    if (Array.isArray(date)) {
      this.selectedDateRange = date;
    }
    this.selectedDate = date;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this.calendarRef?.cd.markForCheck(); // TODO překontrolovat při upgeadu primeng INS-16748
  }

  onMonthYearChange(): void {
    this.updateSize();
  }

  onManualInput(event: InputEvent): void {
    fromEvent(this.calendarRef.el.nativeElement, 'input')
      .pipe(
        // @ts-ignore
        map((event: InputEvent) => (event.target as HTMLInputElement).value),
        debounceTime(1500),
        distinctUntilChanged(),
      )
      .subscribe((value) => {
        let date = dayjs(value, this.dateFormats, true);

        if (value === '' && !this.control?.control?.hasValidator(Validators.required)) {
          // @ts-ignore
          this.setValue(null);
        } else {
          this.setValue(date.toDate());
          this.calendarRef?.cd.markForCheck();
        }

        if (date.isValid()) {
          this.writeValue(date.toDate());
          this.dateSelect.emit(this.calendarRef);
        }
      });

    if (event.inputType === 'insertFromPaste') {
      this.selectedDate = dayjs((event.target as HTMLInputElement).value, this.dateFormats, true).toDate();
      this.dateSelect.emit(this.calendarRef);
      this.calendarRef?.inputfieldViewChild?.nativeElement.blur();
      this.hide();
    }

    if (event?.data?.length) {
      this.hide();
    }
  }

  applyPreset(amount: number = 1, period: dayjs.ManipulateType, hide: boolean = true): void {
    let d;
    if (this.mode === SelectionMode.Range) {
      const f = dayjs().endOf('day').toDate();
      const t = dayjs().add(1, period).endOf('day').toDate();
      d = [f, t];
    }

    if (this.mode === SelectionMode.Single) {
      const shiftMinutes = this.minDate ? 1 : 0;
      d = dayjs().add(amount, period).add(shiftMinutes, 'minutes').toDate();
    }

    // @ts-ignore
    this.writeValue(d);
    // @ts-ignore
    this.setValue(d);

    if (hide) {
      this.hide();
    }
  }

  onClose(calendar?: Calendar): void {
    this.calendarClose.emit(calendar);
  }

  onClear(calendar?: Calendar): void {
    this.calendarClear.emit(calendar);
  }

  onSelect(calendar?: Calendar): void {
    this.writeValue(calendar?.value);
    this.setValue(calendar?.value);
    this.dateSelect.emit(calendar);
  }

  onBlur(calendar?: Calendar): void {
    this.onTouched();
    const value = calendar?.inputfieldViewChild?.nativeElement.value.toString();
    if (this.selectedDate && !dateEqualsBy(value, this.selectedDate, this.comparisonUnit)) {
      this.updateDateTimeFormats();
      let date = dayjs(value, this.dateFormats, true).toDate();

      if (value === '' && !this.control?.control?.hasValidator(Validators.required)) {
        // @ts-ignore
        date = null;
      }

      this.setValue(date);
    }
    this.calendarClose.emit(calendar);
    calendar?.inputfieldViewChild?.nativeElement.blur();
  }

  private updateDateTimeFormats(): void {
    this.dateFormats = this.displayTime
      ? [
          DateTimeFormat.DateTimeFullHoursDayJS,
          DateTimeFormat.DateTimeShortHoursDayJS,
          DateTimeFormat.DateTimeShortFullHoursDayJS,
          DateTimeFormat.DateTimeShortShortHoursDayJS,
        ]
      : [DateTimeFormat.DateDayJS, DateTimeFormat.DateShortDayJS];
    this.formatChange.emit({
      displayTime: this.displayTime,
    });
  }

  private updateSize(): void {
    if (this.calendarRef?.overlay?.style) {
      this.calendarRef.overlay.style.width = 'max-content';
    }
  }

  private hide(): void {
    this.calendarRef.hideOverlay();
  }
}
