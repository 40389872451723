import { AbstractControl, FormGroupDirective, FormGroupName } from '@angular/forms';
import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  SimpleChanges,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { FormLabelDirective } from './form-label.directive';
import { InputFieldDirective } from './input-field.directive';
import { createId } from '@utils';

@Component({
  selector: 'ui-form-element-legacy',
  template: `<label [for]="id" *ngIf="style === 'field'">
      <span [uiElementOutletLegacy]="_label"></span>
      <span *ngIf="optional">(nepovinný údaj)</span>
    </label>
    <legend *ngIf="style === 'fieldset'">
      <span [uiElementOutletLegacy]="_label"></span>
      <span *ngIf="optional">(nepovinný údaj)</span>
    </legend>
    <ng-content></ng-content>
    <div [uiFormFieldMessagesLegacy] #messages></div>`,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'dc-form__group',
  },
})
export class FormElementComponent implements OnChanges, OnDestroy, AfterContentInit, AfterViewInit {
  @ContentChild(InputFieldDirective, { static: true })
  contentEl!: InputFieldDirective;
  @ContentChild(FormLabelDirective, { static: true })
  labelTpl!: FormLabelDirective;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('label') labelStr!: string;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('dcStyle') style: 'field' | 'fieldset' = 'field';
  @Input() optional!: boolean;

  _label!: TemplateRef<any> | string;
  id: string;

  @Input() messages!: any[];

  control!: AbstractControl | null;
  subject = new BehaviorSubject<any>(null);
  ready = false;

  constructor(
    protected detector: ChangeDetectorRef,
    private form: FormGroupDirective,
    @Optional() private group: FormGroupName,
  ) {
    this.id = createId('form-el');
  }

  ngOnChanges(_: SimpleChanges): void {
    if (this.contentEl && this.contentEl.formControlName && !this.control) {
      const controlName = this.contentEl.formControlName;
      this.control = this.form.control.get(controlName) || this.group.control.get(controlName);
    }
  }

  ngAfterContentInit(): void {
    if (this.contentEl) {
      this.contentEl.id = this.id;
    }
    this.setFormLabel();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.checkStatus();
      if (!this.control) return;
      this.control.statusChanges.subscribe(() => {
        this.checkStatus();
      });
    });
  }

  ngOnDestroy(): void {
    this.subject && this.subject.unsubscribe();
  }

  private setFormLabel(): void {
    if (this.labelStr) {
      this._label = this.labelStr;
    } else if (this.labelTpl) {
      this._label = this.labelTpl.templateRef;
    } else {
      this._label = '';
    }
  }

  private checkStatus(): void {
    const control = this.control;
    if (!control) return;
    const errors = control.errors;
    const submited = this.form.submitted;
    this.ready = true;

    if (!errors) return;

    for (const errorKey in errors) {
      if (errors.hasOwnProperty(errorKey)) {
        this.subject.next({ control, errorKey, submited });
      }
    }
  }
}
