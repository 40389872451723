import StackTrace, { StackFrame } from 'stacktrace-js';

import { AbstractLogger } from '../abstract/abstract-logger';
import { HttpClient } from '@angular/common/http';
import { LOGGER_CONFIGURATION } from '../tokens/logger-configuration.token';
import { Logger } from '../abstract/logger';
import { LoggerConfiguration } from '../models/logger-configuration.model';
import { inject } from '@angular/core';

export class ApiErrorLogger extends AbstractLogger implements Logger {
  private readonly http: HttpClient = inject(HttpClient);
  private readonly config: LoggerConfiguration = inject(LOGGER_CONFIGURATION);
  private readonly appVersion: { major: string; minor: string } = require('version.json').version;

  protected onError(customMessage: string, error?: Error): void {
    const message = customMessage ? `${customMessage}. ` : '';
    let content = this.getErrorContent(error);
    content = `${message}${content}`;

    if (error instanceof Object && !error.stack) {
      // @ts-ignore
      Error.captureStackTrace(error);
    }

    if (!error) {
      return;
    }

    StackTrace.fromError(error).then(
      (stackFrames: StackFrame[]) => {
        const stack = stackFrames
          .splice(0, 20)
          .map(function (sf) {
            return sf.toString();
          })
          .join('\n');

        this.http
          .post(this.config.endpoint, {
            content,
            level: 'ERROR',
            stack,
            url: window.location.href,
            timestamp: new Date().toLocaleString(),
            'app.version': `${this.appVersion.major}-${this.appVersion.minor}`,
            'app.name': this.config.appName || null,
          })
          .subscribe((_) => {});
      },
      (stacktraceError) => {
        console.warn(`Unable to report failure: `, stacktraceError);
      },
    );
  }

  private getErrorContent(error: Error | undefined): string {
    let content = '';

    if (error) {
      if (error.message) {
        content = error.message;
      } else if (typeof error === 'string') {
        content = error;
      } else {
        content = JSON.stringify(error as any).toString();
      }
    }

    return content;
  }
}
