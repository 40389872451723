import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ui-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelComponent {
  @Input() label?: string;
  @Input() inputId?: string;
  @Input() badge?: string;
  @Input() trailing?: string;
  @Input() badgeStyleClass?: string;
}
