// immutable assign helper
import { Partition } from '@domain';

export function assign(target: unknown, ...sources: unknown[]) {
  return Object.assign({}, target, ...sources);
}

// bind decorator
export function bind<T extends Function>(
  target: Object,
  propertyKey: string,
  descriptor: TypedPropertyDescriptor<T>,
): TypedPropertyDescriptor<T> | void {
  return {
    configurable: true,
    get(this: T): T {
      const bound: T = descriptor.value!.bind(this);
      Object.defineProperty(this, propertyKey, {
        value: bound,
        configurable: true,
        writable: true,
      });
      return bound;
    },
  };
}

export function memoize<T extends Function>(
  target: any,
  propertyName: string,
  propertyKey: string,
  descriptor: TypedPropertyDescriptor<T>,
) {
  if (descriptor.value != null) {
    _memoizeMethod(descriptor);
  } else if (descriptor.get != null) {
    _memoizeGetAccessor(descriptor);
  } else {
    throw `Only put a Memoize decorator on a method or get accessor.`;
  }
}

function _memoizeMethod(descriptor: TypedPropertyDescriptor<any>): void {
  const originalValue = descriptor.value;
  let hasRun = false;
  let returnedValue: any;

  descriptor.value = function (...args: any[]) {
    if (!hasRun) {
      returnedValue = originalValue.apply(this, args);
      hasRun = true;
    }

    return returnedValue;
  };
}

function _memoizeGetAccessor(descriptor: TypedPropertyDescriptor<any>) {
  const originalGet = descriptor.get;
  const originalSet = descriptor.set;
  let hasRun = false;
  let returnedValue: any;

  descriptor.get = function (...args: []) {
    if (!hasRun) {
      returnedValue = originalGet?.apply(this, args);
      hasRun = true;
    }
    return returnedValue;
  };

  if (descriptor.set != null) {
    descriptor.set = function (...args: any[]) {
      hasRun = false;
      // @ts-ignore
      return originalSet?.apply(this, args);
    };
  }
}

export function compact<T>(arr: T[]): T[] {
  return arr.filter((item) => item !== null && typeof item !== 'undefined');
}

export function identity<T>(value: T) {
  return value as T;
}

export class Range {
  private range: number[] = [];

  constructor(_from: number, to: number, stuff?: any) {
    for (let i = _from; i <= to; i++) {
      this.range.push(typeof stuff === 'undefined' ? i : stuff);
    }
  }

  toList(): number[] {
    return this.range;
  }
}

export function interpolate(template: string, values: Object) {
  // @ts-ignore
  return template.replace(/#{([\w0-9]+)}/g, (val, match) => values[match]);
}

export function isNotEmpty(value: string | number): boolean {
  return value !== null && typeof value !== 'undefined' && value !== '';
}

export function isEmpty(value: any): boolean {
  return !isNotEmpty(value);
}

export function concat(...args: any[]) {
  return args.reduce((acc, val) => {
    acc.concat(val);
    return acc;
  }, []);
}

export function isNonNullObject(value: any): boolean {
  return typeof value === 'object' && value !== null;
}

export function flatten(o: any): unknown {
  if (typeof o !== 'object') throw new Error('Type mismatch');
  return assign(
    {},
    // @ts-ignore
    ...(function _flatten(_) {
      return [].concat(
        ...Object.keys(_).map((k) => (typeof _[k] === 'object' && _[k] !== null ? _flatten(_[k]) : { [k]: _[k] })),
      );
    })(o),
  );
}

export function isPromise(value: any): boolean {
  return (
    value instanceof Promise ||
    (value !== null &&
      typeof value === 'object' &&
      typeof value.then === 'function' &&
      typeof value.catch === 'function')
  );
}

export function partitionBy<T extends object>(key: string, col: any[]): Partition<T> {
  let indexed: Partition<T> = {};
  col.forEach((c) => {
    let index = c[key];
    if (typeof index !== 'undefined') {
      if (typeof indexed[index] !== 'undefined') {
        indexed[index].push(c);
      } else {
        indexed[index] = [c];
      }
    }
  });

  return indexed;
}

export function indexBy(key: string, col: any[]): unknown {
  let indexed: { [key: string]: unknown } = {};
  col.forEach((c) => {
    let index = c[key];
    indexed[index] = c;
  });
  return indexed;
}

let id = 0;
export function createId(ns = ''): string {
  return `${ns}${id++}`;
}

export function humanizeBytes(bytes: number): string {
  if (bytes === 0) {
    return '0 Byte';
  }
  const k = 1024;
  const sizes: string[] = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
  const i: number = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
}

export function createNumericRange(start: number, end: number): number[] {
  return Array.from({ length: end - start + 1 }, (v, k) => k + start);
}

export function zip<A, B>(col1: A[], col2: B[]): [A, B][] {
  const length = Math.min(col1.length, col2.length);
  const pairs = [];
  for (let i = 0; i < length; i++) {
    pairs.push([col1[i], col2[i]]);
  }
  // @ts-ignore
  return pairs;
}

export function filterObjectsByUniqValues<T>(array: T[] = [], property: string): T[] {
  return array.filter(
    // @ts-ignore
    (item, index, array) => array.map((mapObj) => mapObj[property]).indexOf(item[property]) === index,
  );
}

export function uuidV4(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function findDuplicates(arr: any[]): any[] {
  let sorted_arr = arr.slice().sort(); // optimalizace diky sort algoritmu
  let results = [];
  for (let i = 0; i < sorted_arr.length - 1; i++) {
    if (sorted_arr[i + 1] == sorted_arr[i]) {
      results.push(sorted_arr[i]);
    }
  }
  return [...new Set(results)];
}
