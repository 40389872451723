import { Observable, Subject } from 'rxjs';
import { share, throttleTime } from 'rxjs/operators';

import { Injectable } from '@angular/core';

const throttleTimeVal = 250;

@Injectable()
export class WindowScrollService {
  private boundedScrollEvent = new Set<EventTarget>();

  private sub: Subject<Event | null> = new Subject();

  constructor() {
    this.bind(window);
  }

  windowScroll$(): Observable<any> {
    return this.sub.pipe(throttleTime(throttleTimeVal), share());
  }

  bind(target: EventTarget): void {
    if (!this.boundedScrollEvent.has(target)) {
      target.addEventListener('scroll', this.handleWindowScroll.bind(this));
      this.boundedScrollEvent.add(target);
    }
  }

  unbind(target: EventTarget) {
    target.removeEventListener('scroll', this.handleWindowScroll);
    this.boundedScrollEvent.delete(target);
  }

  private handleWindowScroll(): void {
    this.sub.next(null);
  }

  async waitForScrollEnd(): Promise<boolean> {
    let lastChangedFrame = 0;
    let lastX = window.scrollX;
    let lastY = window.scrollY;

    return new Promise((resolve) => {
      function tick(frames: number): void {
        if (frames >= 500 || frames - lastChangedFrame > 20) {
          resolve(true);
        } else {
          if (window.scrollX != lastX || window.scrollY != lastY) {
            lastChangedFrame = frames;
            lastX = window.scrollX;
            lastY = window.scrollY;
          }
          requestAnimationFrame(tick.bind(null, frames + 1));
        }
      }
      tick(0);
    });
  }
}
