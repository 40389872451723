<ui-dialog
    [display]="true"
    [disabled]="form.invalid"
    [storing]="loading"
    acceptButtonLabel="Nahlásit chybu"
    styleClass="min-h-[25rem] w-[50rem]"
    [acceptButtonTooltip]="form.invalid ? validationMessages.fillAllMandatoryFields : ''"
    header="Nahlásit chybu"
    (reject)="closeModal()"
    (accept)="reportIssue()"
>
    <ui-message *ngIf="error" severity="error" [title]="errorMessage" />
    <form class="flex flex-col gap-y-5" [formGroup]="form">
        <div>
            <ui-label inputId="category" label="Kategorie" trailing="Povinné" />
            <ui-dropdown
                [options]="reportIssueCategories | keyvalue"
                optionLabel="value"
                optionValue="key"
                styleClass="w-full"
                inputId="category"
                name="category"
                formControlName="category"
                appendTo="body"
            />
            <ui-validation-error *ngIf="formService.hasError(form.controls.category)">
                {{ validationMessages.required }}
            </ui-validation-error>
        </div>
        <div>
            <ui-label inputId="description" label="Co se stalo" trailing="Povinné" />
            <textarea
                formControlName="description"
                id="description"
                pInputTextarea
                type="text"
                class="w-full resize-none"
                rows="3"
            ></textarea>
            <ui-validation-error *ngIf="formService.hasError(form.controls.description)">
                {{ validationMessages.required }}
            </ui-validation-error>
        </div>
        <p-divider align="left">
            <label class="mt-0 px-2 text-slate-400">Doplňující údaje</label>
        </p-divider>
        <div class="grid grid-cols-3 gap-5">
            <div>
                <ui-label inputId="date" label="Datum a čas" />
                <ui-calendar
                    inputId="date"
                    formControlName="date"
                    mode="single"
                    [showPresets]="true"
                    [showTime]="true"
                    [maxDate]="maxDate"
                    [preselectedDate]="maxDate"
                >
                    <ui-validation-error *ngIf="formService.hasError(form.controls.date, 'invalidDate')">
                        {{ validationMessages.dateTime.invalid }}
                    </ui-validation-error>
                    <ui-validation-error *ngIf="formService.hasError(form.controls.date, 'inFuture')">
                        {{ validationMessages.dateTime.inFuture }}
                    </ui-validation-error>
                </ui-calendar>
            </div>
            <ng-container *ngIf="form.controls.category.value === 'GENERAL'">
                <div class="col-span-2">
                    <ui-label inputId="fishtag" label="Kód chyby" />
                    <input pInputText id="fishtag" name="fishtag" formControlName="fishtag" class="w-full" />
                </div>
            </ng-container>

            <ng-container *ngIf="form.controls.category.value === 'CLAIM_REGISTRATION'">
                <div class="col-span-2">
                    <ui-label inputId="url" label="URL odkaz s hlášenkou" />
                    <input pInputText id="url" name="url" formControlName="url" class="w-full" />
                    <ui-validation-error *ngIf="formService.hasError(form.controls.url)">
                        {{ validationMessages.required }}
                    </ui-validation-error>
                </div>
            </ng-container>
        </div>
        <ng-container *ngIf="form.controls.category.value === 'GENERAL'">
            <div>
                <ui-label label="Soubory" />
                <p-fileUpload
                    #docsUpload
                    chooseLabel="Procházet soubory"
                    [showUploadButton]="false"
                    [customUpload]="true"
                    [multiple]="true"
                    [showCancelButton]="false"
                    [maxFileSize]="reportIssueOpt.docsUpload.maxFileSize"
                    [accept]="reportIssueOpt.docsUpload.allowedFileTypes"
                    [invalidFileTypeMessageSummary]="reportIssueOpt.docsUpload.invalidFileTypeMessage"
                    invalidFileTypeMessageDetail=""
                    [invalidFileSizeMessageSummary]="reportIssueOpt.docsUpload.invalidMaxFileSizeMessage"
                    invalidFileSizeMessageDetail=""
                    (onSelect)="onSelectFiles()"
                >
                    <ng-template pTemplate="toolbar">
                        <div *ngIf="docsUpload.files?.length" class="flex items-center text-neutral-500">
                            <div>
                                Celková velikost
                                <span class="font-semibold">{{ docsUpload.files.length }}</span>
                                <span>
                                    {{ 'soubor' | pluralize: docsUpload.files.length : { '1': 'u', '2': 'ů' } }}:
                                </span>
                                <span class="font-semibold">{{ getHumanFileSize(totalSize) }}</span>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="chooseicon">
                        <ui-svg icon="Plus" strokeColor="stroke-white" />
                    </ng-template>
                    <ng-template let-file pTemplate="file">
                        <div class="grid grid-cols-[auto_6rem_4rem] items-center">
                            <div class="truncate pr-4">{{ file.name }}</div>
                            <div>{{ getHumanFileSize(file.size) }}</div>
                            <div class="flex justify-end">
                                <ui-button
                                    icon="pi pi-times-circle"
                                    styleClass="p-button-rounded p-button-icon-only p-button-text"
                                    (onClick)="onRemove(docsUpload, file, $event)"
                                />
                            </div>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <div class="p-fileupload-placeholder">
                            Přetáhněte soubory sem nebo klikněte na
                            <ins>Procházet soubory</ins>
                            .
                        </div>
                    </ng-template>
                </p-fileUpload>
                <div class="p-fileupload-hint break-words">
                    Povolené typy dokumentů:
                    {{ reportIssueOpt.docsUpload.allowedFileTypes }}
                </div>
            </div>
            <div class="flex gap-5">
                <ui-switch
                    inputId="vpn"
                    label="Jsem na VPN"
                    mode="inline"
                    styleClass="ui-switch flex gap-x-2"
                    formControlName="vpn"
                />
                <ui-switch
                    inputId="otherColleagues"
                    label="Děje se to ostatním kolegům"
                    mode="inline"
                    styleClass="ui-switch flex gap-x-2"
                    formControlName="otherColleagues"
                />
            </div>
        </ng-container>
    </form>
</ui-dialog>
