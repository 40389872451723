import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ToastButton, ToastMessage } from '@abstractions';

@Component({
  selector: 'ui-toast-buttons',
  templateUrl: './toast-buttons.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastButtonsComponent {
  @Input() message!: ToastMessage;
  @Input() toastButtons!: ToastButton[];
}
