import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {
  private cache = {} as any;
  private rawCache = {} as any;
  private ns = 'ns-core-v2';

  getProperty(key: string, _default: any = null, useCache = true): any {
    if (useCache && typeof this.cache[key] !== 'undefined') return this.cache[key];
    const localData = this.getLocalData();
    const value = localData[key];
    this.cache[key] = value;
    return typeof value === 'undefined' ? _default : value;
  }

  getRawProperty(key: string, _default = null) {
    if (typeof this.rawCache[key] !== 'undefined') return this.rawCache[key];
    const value = JSON.parse(localStorage.getItem(key) ?? '');
    this.rawCache[key] = value;
    return typeof value === 'undefined' ? _default : value;
  }

  setRawProperty(key: string, value: unknown, _default = null) {
    this.rawCache[key] = value;
    const encoded = JSON.stringify(value);
    localStorage.setItem(key, encoded);
  }

  setProperty(key: string, value: unknown): void {
    let localData = this.getLocalData();
    localData[key] = value;
    this.cache[key] = value;
    const encoded = JSON.stringify(localData);
    localStorage.setItem(this.ns, encoded);
  }

  private getLocalData() {
    const item = localStorage.getItem(this.ns);
    return JSON.parse(item as string) || {};
  }
}
