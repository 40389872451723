import { ToastService as AbstractToastService, ToastInstanceType, ToastMessage } from '@abstractions';

import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { uuidV4 } from '@utils';

@Injectable()
export class ToastService extends AbstractToastService {
  override toasts$ = new BehaviorSubject<ToastMessage[]>([]);
  override toasts: ToastMessage[] = [];

  override add(message: ToastMessage): string | undefined {
    if (!message) {
      return '';
    }

    const toast = message.id ? message : { ...message, id: uuidV4() };
    if (toast.data?.code && toast.data?.instanceType !== ToastInstanceType.Multiple) {
      const existToast = this.toasts?.find((t) => t.data?.code === toast.data?.code);
      if (existToast && !existToast.data?.override) {
        return existToast.id;
      }
      if (existToast && existToast.data?.override) {
        this.close(existToast?.id ?? '');
      }
    }
    this.toasts.push(toast);
    this.toasts$.next(this.toasts);
    return toast.id;
  }

  override close(id: string): void {
    this.toasts = this.toasts.filter((t) => t.id !== id);
    this.toasts$.next(this.toasts);
  }
}
