import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[formGroup],formGroup',
  exportAs: 'dcFormGroup',
})
export class FormGroupDirective {
  @HostBinding('class') _class = 'dc-form';

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('class') set styleClass(styleClass: string) {
    if (styleClass) this._class = `dc-form ${styleClass}`;
  }
}
