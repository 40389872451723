import { AccessControlModule, FeatureFlagsModule, UserModule, WebsocketModule } from '@framework';
import { CommonModule, NgOptimizedImage } from '@angular/common';

import { BadgeModule } from 'primeng/badge';
import { BaseElementsModule } from '../../legacy/base-elements/base-elements.module';
import { ButtonComponent } from '../../primeng/button/button.component';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { DropdownComponent } from '../../primeng/dropdown/dropdown.component';
import { FormsModule } from '@angular/forms';
import { HeaderComponent } from './header.component';
import { MenuModule } from 'primeng/menu';
import { NgModule } from '@angular/core';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { RouterModule } from '@angular/router';
import { SecuredImagesModule } from '../../../directives/secured-images/secured-images.module';
import { SplitButtonModule } from 'primeng/splitbutton';
import { SvgIconModule } from '../../non-primeng/svg-icon/svg-icon.module';
import { headerOpt } from './header.opt';
import { headerOptDI } from './header.di';

@NgModule({
  declarations: [HeaderComponent],
  providers: [{ provide: headerOptDI, useValue: headerOpt }],
  exports: [HeaderComponent],
  imports: [
    CommonModule,
    RouterModule,
    BaseElementsModule,
    AccessControlModule,
    DividerModule,
    ButtonModule,
    OverlayPanelModule,
    UserModule,
    SecuredImagesModule,
    WebsocketModule,
    FeatureFlagsModule,
    FormsModule,
    BadgeModule,
    NgOptimizedImage,
    SvgIconModule,
    ButtonComponent,
    DropdownComponent,
    SplitButtonModule,
    MenuModule,
  ],
})
export class HeaderModule {}
