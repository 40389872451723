import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dateDiff' })
export class DateDiffPipe implements PipeTransform {
  readonly MS_IN_SECONDS = 1000;
  readonly SECONDS_IN_DAY = 86400;

  transform(dateStr: string): string {
    if (!dateStr) return '';

    const date = new Date(dateStr);
    const now = new Date();

    date.setHours(0, 0, 0, 0);
    now.setHours(0, 0, 0, 0);

    const diff = date.getTime() - now.getTime();
    const delta = diff / this.MS_IN_SECONDS;
    const days = Math.floor(delta / this.SECONDS_IN_DAY);

    return String(days);
  }
}
