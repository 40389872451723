import { Component, ElementRef, Input } from '@angular/core';

import { BlockableUI } from 'primeng/api';

@Component({
  selector: 'ui-block-ui-container',
  templateUrl: './block-ui-container.component.html',
})
export class BlockUiContainerComponent implements BlockableUI {
  @Input() public readonly styleClass!: string;

  constructor(private elRef: ElementRef) {}

  public getBlockableElement(): HTMLElement {
    return this.elRef.nativeElement.children[0];
  }
}
