import { ChangeDetectorRef, Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';

import { FeatureFlagsService } from './feature-flags.service';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Directive({
  selector: 'fwkFeature,[fwkFeature]',
})
export class FeatureFlagsDirective implements OnDestroy {
  sub!: Subscription;
  inverse = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private featureFlags: FeatureFlagsService,
    private cd: ChangeDetectorRef,
  ) {}

  @Input() set fwkFeature(_name: string | [string, string]) {
    let name;
    if (typeof _name !== 'string') {
      name = _name[1];
      this.inverse = _name[0] === 'not';
    } else {
      name = _name as string;
    }

    this.sub = this.featureFlags.hasEnabled$(name).pipe(distinctUntilChanged()).subscribe(this.updateView.bind(this));
  }

  ngOnDestroy() {
    this.sub && this.sub.unsubscribe();
  }

  private updateView(_visible: boolean | undefined): void {
    const visible = this.inverse ? !_visible : _visible;
    visible ? this.viewContainer.createEmbeddedView(this.templateRef) : this.viewContainer.clear();
    this.cd.detectChanges();
  }
}
