import { ApiSession, HttpOpt } from './api.model';
import { Inject, Injectable } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, tap, timeout } from 'rxjs/operators';

import { ApiService } from './api.service';
import { HttpResponse } from '@angular/common/http';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { appHttpOptDI } from './api.di';

@Injectable()
export class ApiSessionService {
  storage: Map<string, any>;
  ttl: Map<string, number>;

  readonly lifeTime = 24 * 60 * 60 * 1000;

  constructor(
    @Inject(appHttpOptDI) private opt: HttpOpt,
    private localStorage: LocalStorageService,
    private api: ApiService,
  ) {
    this.storage = new Map();
    this.ttl = new Map();
  }

  checkSession$(partnerId: string): Observable<ApiSession> {
    if (this.hasSessionInStorage(partnerId)) {
      return this.getSession(partnerId);
    }
    return this.api
      .post(
        this.opt.startSessionPath,
        {
          partnerId,
        },
        { observe: 'response' },
      )
      .pipe(
        timeout(this.opt.startSessionTimeout),
        map((res: HttpResponse<any>) => {
          const { sessionId } = res.body;
          if (res.status !== 200 || !sessionId) {
            this.setSession(partnerId, `error ${res.status}`);
            throw new Error(`Bad start session response`);
          }
          return {
            kind: 'session',
            sessionId,
          };
        }),
        tap((data) => {
          this.setSession(partnerId, data);
        }),
        catchError((error) =>
          observableOf({
            kind: 'session',
            sessionId: null,
            error: error.message,
          }),
        ),
      ) as Observable<ApiSession>;
  }

  clearSession(): void {
    this.api.setSessionId(null);
    this.localStorage.setProperty('sessionId', null);
  }

  private hasSessionInStorage(partnerId: string): boolean {
    return !!(
      (this.storage.has(partnerId) && this.ttl.has(partnerId) && this.ttl?.get(partnerId)) ??
      0 > +new Date() - this.lifeTime
    );
  }

  private setSession(partnerId: string, data: any): void {
    this.api.setSessionId(data.sessionId);
    this.storage.set(partnerId, data);
    this.localStorage.setProperty('sessionId', data);
    this.ttl.set(partnerId, +new Date());
  }

  private getSession(partnerId: string): Observable<ApiSession> {
    const data = this.storage.get(partnerId);
    this.api.setSessionId(data.sessionId);
    return observableOf(data);
  }
}
