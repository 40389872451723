import { Message } from 'primeng/api/message';

export enum ToastType {
  Info = 'info',
  Warn = 'warn',
  Fatal = 'fatal',
  Error = 'error',
  Success = 'success',
  Empty = 'empty',
  IncomingCall = 'incoming-call',
}

export enum ToastMessageType {
  IncomingCall = 'incoming-call',
  Fishtag = 'api-error-fishtag',
  TaskCountChange = 'task-count-change',
  ServiceUnavailable = 'service-unavailable',
  IpexTicket = 'ipex-ticket',
}

export enum ToastInstanceType {
  Multiple = 'multiple',
}

export enum ToastPositionType {
  Center = 'center',
  Right = 'right',
}

export interface ToastMessage extends Message {
  id?: string;
  data?: {
    override?: boolean;
    instanceType?: ToastInstanceType;
    code?: ToastMessageType;
    routerLink?: any[];
    link?: boolean;
  };
}

export interface ToastButton {
  label: string;
  enabled: boolean;
  type: ToastMessageType;
  action: (message: ToastMessage) => void;
}
