import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';

import { UserAccountService, UserProfileService, UserService, permissions } from '@framework';
import { filter, switchMap } from 'rxjs/operators';
import { HeaderService } from './services/header.service';
import { KeycloakService } from 'keycloak-angular';
import { Menu } from 'primeng/menu/menu';
import { MenuItem } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { logoutRedirectUriDI } from './header.di';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'ui-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  @Input() disableHamburgerMenu: boolean = false;

  @Output() clickLogo: EventEmitter<void | null> = new EventEmitter<void | null>();
  @Output() handleLinkNavigation = new EventEmitter<string[]>();

  @ViewChild('op') overlayPanel!: OverlayPanel;
  @ViewChild('menuToggle') menuToggle!: ElementRef;
  @ViewChild('userOptions') userOptions: Menu | undefined;

  readonly permissions = permissions;

  isUserLogged: boolean = false;
  realName!: string;
  email!: string;
  avatar?: string;

  userOptionItems: MenuItem[] = [
    {
      icon: 'Logout',
      label: 'Odhlásit',
      command: () => {
        this.performLogout();
      },
    },
  ];

  private readonly defaultRedirectUrl: string = inject(logoutRedirectUriDI);

  constructor(
    private readonly profile: UserProfileService,
    private readonly userService: UserService,
    private readonly account: UserAccountService,
    private readonly headerService: HeaderService,
    private readonly cd: ChangeDetectorRef,
    private readonly keycloak: KeycloakService,
    private destroyRef: DestroyRef,
  ) {}

  ngOnInit(): void {
    this.userService
      .isLogged$()
      .pipe(
        switchMap((isUserLogged) => {
          this.isUserLogged = isUserLogged;
          return this.profile.getProfile$();
        }),
      )
      .subscribe((profile) => {
        this.userService.user$
          .pipe(
            filter((user) => !!user),
            switchMap((user) => this.account.getById(user.userAccountId)),
            filter((account) => !!account),
            takeUntilDestroyed(this.destroyRef),
          )
          .subscribe((account) => {
            this.avatar = account.internalPhoto;
            this.realName = `${account.firstName} ${account.lastName}`;
            this.email = account.email;

            this.cd.markForCheck();
          });

        if (!this.realName) {
          this.realName = profile?.realName || profile?.username;
          this.cd.markForCheck();
        }
      });

    this.headerService.handleMenuDisplay$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.handleMenuDisplay();
    });
  }

  handleClickOnLogo(): void {
    this.handleMenuDisplay();
    this.clickLogo.emit(null);
  }

  handleMenuDisplay(): void {
    if (this.menuToggle && this.overlayPanel) {
      this.menuToggle.nativeElement.checked = false;
      this.overlayPanel?.hide();
    }
  }

  handleUserAccountDisplay(event?: MouseEvent): void {
    this.userOptions?.toggle({
      originalEvent: event,
      currentTarget: (event?.target as HTMLElement).closest('button'),
      relativeAlign: true,
    } as unknown as Event);
  }

  @HostListener('document:keydown', ['$event'])
  handleDocumentLogout(event: KeyboardEvent): void {
    if (event.shiftKey && event.altKey && event.code === 'KeyL') {
      event.preventDefault();
      this.performLogout();
    }
  }

  hasScrollbar(): boolean {
    return document.body.scrollHeight > document.body.clientHeight;
  }

  private performLogout(): void {
    this.keycloak.logout(this.getDashboardUrl());
  }

  private getDashboardUrl(): string {
    const url = new URL(window.location.href);
    url.pathname = `/${this.defaultRedirectUrl}`;
    return url.toString();
  }
}
