export default class UserUtils {
  static mapRights(decodedAccessToken: any) {
    const memberOf = {} as any;
    const resourceAccess = decodedAccessToken.resource_access;
    const coreResources = resourceAccess.core;
    if (coreResources && coreResources.roles && coreResources.roles.length > 0) {
      coreResources.roles.forEach((role: string) => {
        memberOf[role] = true;
      });
    } else {
      memberOf['partnerSearch'] = true;
    }
    return memberOf;
  }
}
