import { LocationStrategy } from '@angular/common';
import { SafeResourceUrl } from '@angular/platform-browser';

export function saveBlob(blob: Blob, fileName: string): void {
  saveBlobByUrl(window.URL.createObjectURL(blob), fileName);
}

export function saveBlobByUrl(url: string, fileName: string): void {
  let a = window.document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export function isImageElement(element: HTMLImageElement | HTMLDivElement): element is HTMLImageElement {
  return element.nodeName.toLowerCase() === 'img';
}

export function setImage(
  element: HTMLImageElement | HTMLDivElement,
  imagePath: string | SafeResourceUrl,
  useSrcset = false,
) {
  if (isImageElement(element)) {
    if (useSrcset) {
      element.srcset = imagePath as string;
    } else {
      element.src = imagePath as string;
    }
  } else {
    element.style.backgroundImage = `url('${imagePath}')`;
  }
  return element;
}

export function getBaseUrl(location: LocationStrategy): string {
  return `${document.location.origin}/${location.getBaseHref()}`;
}
