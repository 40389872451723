import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { Observable, Subject, combineLatest } from 'rxjs';
import { debounceTime, filter, map, pairwise, sampleTime, share, startWith } from 'rxjs/operators';

import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';

@Injectable()
export class AppService {
  navEnd$: Observable<any>;
  previousUrl!: string;

  private bodyClass$: Subject<boolean> = new Subject<boolean>();
  private scrollListeners = new WeakMap<any, Observable<any>>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private document: any,
    private title: Title,
  ) {
    this.navEnd$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute.root.snapshot),
    );

    this.navEnd$
      .pipe(
        map(() => this.router.url),
        pairwise(),
      )
      .subscribe(([prev]) => {
        this.previousUrl = prev;
      });
  }

  autoScroll$(): Observable<null> {
    return this.navEnd$.pipe(
      pairwise(),
      filter((snapshotPair) => {
        return !snapshotPair.some((snapshot) => {
          return snapshot && this.hasRouteDisabledAutoscroll(snapshot);
        });
      }),
      map(() => null),
    );
  }

  disabledFloatingButton$(): Observable<boolean> {
    return this.navEnd$.pipe(
      map((snapshot) => {
        return snapshot && this.hasRouteDisabledFloatingButton(snapshot);
      }),
    );
  }

  getScrollListener$(scrollTarget: Window | null = null): Observable<any> | undefined {
    if (!scrollTarget) {
      scrollTarget = window;
    }

    if (this.scrollListeners.has(scrollTarget)) {
      return this.scrollListeners.get(scrollTarget);
    }

    return new Observable((observer) => {
      const eventName = 'scroll';
      const handler = (event: any) => observer.next(event);
      const options = { passive: true, capture: false };
      scrollTarget?.addEventListener(eventName, handler, options);
      return () => scrollTarget?.removeEventListener(eventName, handler, options);
    }).pipe(sampleTime(50), share(), startWith(''), debounceTime(250));
  }

  getBodyClass$(): Observable<string> {
    return combineLatest(
      this.bodyClass$.asObservable().pipe(startWith(false)),
      this.router.events.pipe(filter((s) => s instanceof NavigationEnd)),
    ).pipe(
      debounceTime(50),
      map(([visibleModal]) => {
        const bodyClass = this.activatedRoute?.firstChild?.snapshot.data['bodyClass'] || '';
        return (visibleModal ? 'c-modal--is-open' : 'c-modal--is-closed') + ` ${bodyClass}`;
      }),
    );
  }

  getDynamicPageTitle() {
    const appTitle = this.title.getTitle();
    return this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => {
        let child = this.activatedRoute.firstChild;
        while (child?.firstChild) {
          child = child.firstChild;
        }
        if (child?.snapshot.data['title']) {
          return child.snapshot.data['title'];
        }
        return appTitle;
      }),
    );
  }

  goBack(): boolean {
    if (!this.previousUrl) {
      return false;
    } else {
      this.router.navigateByUrl(this.previousUrl);
      return true;
    }
  }

  getScrollOffset(): number {
    return window.scrollY || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0;
  }

  hideBodyScrollBar(): void {
    this.bodyClass$.next(true);
  }

  showBodyScrollBar(): void {
    this.bodyClass$.next(false);
  }

  private hasRouteDisabledAutoscroll(route: any): boolean {
    if (route.data['autoscroll'] === false) {
      return true;
    } else if (route.children.length > 0) {
      return route.children.some((r: any) => {
        return this.hasRouteDisabledAutoscroll(r);
      });
    } else {
      return false;
    }
  }

  private hasRouteDisabledFloatingButton(route: any): boolean {
    if (route.data['floatingButton'] === false) {
      return true;
    } else if (route.firstChild) {
      return route.firstChild.data['floatingButton'] === false;
    } else {
      return false;
    }
  }
}
