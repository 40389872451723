import * as ace from 'ace-builds';

import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { Ace } from 'ace-builds';

@Component({
  selector: 'ui-cv--code-editor-legacy',
  templateUrl: './code-editor.component.html',
  styleUrls: ['./code-editor.scss'],
})
export class CodeEditorComponent implements OnInit, AfterViewInit {
  @Input() public readonly textForEdit!: string;
  @Input() public readonly isCorrect!: boolean;
  @Input() public set refObject(name: string) {
    if (this.aceEditor) {
      this.aceEditor.session.setValue(this.textForEdit || '');
    }
  }

  @Output() private readonly textOutput: EventEmitter<string> = new EventEmitter<string>();
  @Output() private readonly editorBlur: EventEmitter<string> = new EventEmitter<string>();

  public aceEditor!: Ace.Editor;
  public aceEditorExtended = false;

  @ViewChild('aceEditor') private editor!: ElementRef<HTMLElement>;

  public ngOnInit(): void {
    this.aceEditor && this.aceEditor.session.setValue(this.textForEdit || '');
    this.aceEditorExtended = false;
  }

  public ngAfterViewInit(): void {
    this.initAceEditor();
  }

  private initAceEditor(): void {
    ace.config.set('fontSize', '14px');
    ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
    this.aceEditor = ace.edit(this.editor.nativeElement);
    this.aceEditor.setTheme('ace/theme/clouds');
    this.aceEditor.setShowPrintMargin(false);
    this.aceEditor.on('change', () => {
      this.textOutput.next(this.aceEditor.getValue());
    });
    this.aceEditor.on('blur', () => {
      this.editorBlur.next('blur');
    });

    this.aceEditor.session.setValue(this.textForEdit || '');
    this.aceEditor.session.setMode('ace/mode/pgsql');
    this.aceEditor.session.setTabSize(2);
  }
}
