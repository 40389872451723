import { ConnectableObservable, Observable } from 'rxjs';
import { Profile, User } from '../model/user.model';
import { distinctUntilKeyChanged, filter, map, publishReplay } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { LocalStorageService } from '../../local-storage/local-storage.service';
import { UserService } from '../user.service';

@Injectable()
export class UserProfileService {
  key = 'dc__profile';
  profile$: ConnectableObservable<Profile>;
  maybeProfile$: ConnectableObservable<Profile | null>;
  preferences: any;

  constructor(
    private user: UserService,
    private ls: LocalStorageService,
  ) {
    this.profile$ = this.user.user$.pipe(
      filter((s: User) => s?.state === 'logged' || s?.state === 'expiring'),
      map((s: User) => s.profile),
      publishReplay(1),
    ) as ConnectableObservable<Profile>;

    this.maybeProfile$ = this.user.user$.pipe(
      map((s: User) => (s?.state === 'logged' || s?.state === 'expiring' ? s.profile : null)),
      publishReplay(1),
    ) as ConnectableObservable<Profile | null>;

    this.profile$.connect();
    this.maybeProfile$.connect();

    this.preferences = this.ls.getProperty(this.key, {});
  }

  getProfile$(): Observable<Profile> {
    return this.profile$.pipe(distinctUntilKeyChanged('username')) as Observable<Profile>;
  }

  getPreference(key: string, defaultValue: any = null): any {
    if (typeof this.preferences[key] !== 'undefined') {
      return this.preferences[key];
    }

    return defaultValue;
  }

  setPreference(key: string, value: any): any {
    this.preferences[key] = value;
    this.ls.setProperty(this.key, this.preferences);
    return value;
  }
}
