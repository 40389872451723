import { Injectable, inject } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { featureFlagsOptDi } from './feature-flags.di';
import { interpolate } from '@utils';

import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsService {
  private readonly opt = inject(featureFlagsOptDi);
  featureFlagsChecked = new Map<string, boolean>();

  constructor(private api: ApiService) {}

  hasEnabled$(name: string): Observable<boolean | undefined> {
    return this.featureFlagsChecked.has(name)
      ? observableOf(this.featureFlagsChecked.get(name))
      : this.api.get(interpolate(this.opt.featurePermitPath, { name })).pipe(
          map((_) => true),
          catchError((_) => observableOf(false)),
          tap((isEnabled: boolean) => {
            this.featureFlagsChecked.set(name, isEnabled);
          }),
        );
  }

  async hasEnabled(name: string): Promise<boolean | undefined> {
    return this.hasEnabled$(name).pipe(take(1)).toPromise();
  }

  async prepare(_name: string | string[]): Promise<void> {
    const names: string[] = Array.isArray(_name) ? (_name as string[]) : [_name as string];
    return Promise.all(names.map((name) => this.hasEnabled(name))).then((_) => {});
  }
}
