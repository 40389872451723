import { RouterStateSerializer, StoreRouterConnectingModule, routerReducer } from '@ngrx/router-store';

import { MergedRouterStateSerializer } from './merged-route-serialzer';
import { NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { StoreModule } from '@ngrx/store';

export const routerStateConfig = {
  stateKey: 'router', // state-slice name for routing state
};

@NgModule({
  imports: [
    StoreModule.forFeature(routerStateConfig.stateKey, routerReducer),
    StoreRouterConnectingModule.forRoot(routerStateConfig),
  ],
  exports: [StoreModule, StoreRouterConnectingModule],
  providers: [
    {
      provide: RouterStateSerializer,
      useClass: MergedRouterStateSerializer,
    },
  ],
})
export class NgrxRouterStoreModule {
  constructor(private router: Router) {
    if (!router) {
      console.error('NgrxRouterStoreModule must be imported in the same same level as RouterModule');
    }
  }
}
