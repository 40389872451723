import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';

import { KeycloakService } from 'keycloak-angular';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ApiErrorInterceptor implements HttpInterceptor {
  private readonly keycloak = inject(KeycloakService);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error) => {
        if (error?.constructor?.name !== 'HttpErrorResponse') {
          return throwError(error);
        }

        if (error.url.includes('claim-management') || error.url.includes('inspection/start')) {
          return throwError(error);
        }

        // @ts-ignore
        if (error.status === 401 && !error.url.includes('utils/feature/permit') && this.keycloak.isLoggedIn()) {
          this.keycloak.login({ redirectUri: window.location.href });
        }

        return throwError(error);
      }),
    );
  }
}
