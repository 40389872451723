import { AccessControlService } from './access-control.service';
import { NgModule } from '@angular/core';
import { PermissionDirective } from './access-control.directive';

export { AccessControlService } from './access-control.service';

@NgModule({
  declarations: [PermissionDirective],
  imports: [],
  providers: [AccessControlService],
  exports: [PermissionDirective],
})
export class AccessControlModule {}
