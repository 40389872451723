import { BehaviorSubject } from 'rxjs';
import { ToastMessage } from './model/toast.model';

export abstract class ToastService {
  abstract toasts$: BehaviorSubject<ToastMessage[]>;
  abstract toasts: ToastMessage[];

  abstract add(message: ToastMessage): string | undefined;
  abstract close(id: string): void;
}
