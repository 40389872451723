<ng-container [ngSwitch]="variant()">
    <p-progressSpinner *ngSwitchCase="'default'" [styleClass]="styleClass()"></p-progressSpinner>

    <ng-container *ngSwitchCase="'jelly'">
        <div class="jelly"></div>
        <svg width="0" height="0" class="jelly-maker">
            <defs>
                <filter id="uib-jelly-ooze">
                    <feGaussianBlur in="SourceGraphic" stdDeviation="6.25" result="blur"></feGaussianBlur>
                    <feColorMatrix
                        in="blur"
                        mode="matrix"
                        values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
                        result="ooze"
                    ></feColorMatrix>
                    <feBlend in="SourceGraphic" in2="ooze"></feBlend>
                </filter>
            </defs>
        </svg>
        @if (message()?.length) {
            <span>{{ message() }}</span>
        }
    </ng-container>

    <ng-container *ngSwitchCase="'ring'">
        <svg width="40px" height="40px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" [ngClass]="styleClass()">
            <circle cx="50" cy="50" r="23" class="stroke-primary-50" stroke-width="8" fill="none"></circle>
            <circle
                cx="50"
                cy="50"
                r="23"
                class="stroke-primary-400"
                stroke-width="9"
                stroke-linecap="round"
                fill="none"
            >
                <animateTransform
                    attributeName="transform"
                    type="rotate"
                    repeatCount="indefinite"
                    dur="1.1764705882352942s"
                    values="0 50 50;180 50 50;720 50 50"
                    keyTimes="0;0.5;1"
                ></animateTransform>
                <animate
                    attributeName="stroke-dasharray"
                    repeatCount="indefinite"
                    dur="1.1764705882352942s"
                    values="14.451326206513047 130.06193585861743;50.57964172279567 93.9336203423348;14.451326206513047 130.06193585861743"
                    keyTimes="0;0.5;1"
                ></animate>
            </circle>
        </svg>
    </ng-container>
</ng-container>
