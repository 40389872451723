import { negativeInfinity, positiveInfinity } from '@domain';

import dayjs from 'dayjs';

export const isPositiveInfinity = (inputDate: string | Date): boolean => {
  const date = convertDate(inputDate);
  return !date || date.includes(positiveInfinity);
};

export const isNegativeInfinity = (inputDate: string | Date): boolean => {
  const date = convertDate(inputDate);
  return !date || date.includes(negativeInfinity);
};

export const isValidDate = (date: Date): boolean => {
  return date instanceof Date && !isNaN(date.getTime());
};

export const convertDate = (inputDate: string | Date): string => {
  return inputDate instanceof Date ? inputDate.toString() : inputDate;
};

/**
 * Compares given string dates by given unit.
 * @param firstDate First date. Not null.
 * @param secondDate Second date. Not null.
 * @param unit Unit. Not null.
 */
export function dateEqualsBy(firstDate: Date | string, secondDate: Date | string, unit: dayjs.UnitType): boolean {
  return dayjs(firstDate).isSame(secondDate, unit);
}
