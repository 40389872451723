import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  Renderer2,
  Signal,
  signal,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { Dropdown, DropdownItem, DropdownModule } from 'primeng/dropdown';
import { FilterService, PrimeNGConfig } from 'primeng/api';

import { AutoFocusModule } from 'primeng/autofocus';
import { CommonMessages } from '@domain';
import { CommonModule } from '@angular/common';
import { LoaderModule } from '../../non-primeng/loader/loader.module';
import { SpinnerIcon } from 'primeng/icons/spinner';
import { SvgIconModule } from '../../non-primeng/svg-icon/svg-icon.module';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'ui-dropdown-item',
  template: `
    <li
      [id]="id"
      (click)="onOptionClick($event)"
      (mouseenter)="onOptionMouseEnter($event)"
      role="option"
      [attr.aria-label]="label"
      [attr.aria-setsize]="ariaSetSize"
      [attr.aria-posinset]="ariaPosInset"
      [attr.aria-selected]="selected"
      [attr.data-p-focused]="focused"
      [attr.data-p-highlight]="selected"
      [attr.data-p-disabled]="disabled"
      [ngStyle]="{ height: itemSize + 'px' }"
      [ngClass]="{ 'p-dropdown-item': true, 'p-highlight': selected, 'p-disabled': disabled, 'p-focus': focused }"
    >
      <span *ngIf="!template">{{ label ?? 'empty' }}</span>
      <ng-container *ngTemplateOutlet="template; context: { $implicit: option }"></ng-container>
    </li>
  `,
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, DropdownModule],
})
export class DropdownItemComponent extends DropdownItem {
  constructor() {
    super();
  }
}

@Component({
  selector: 'ui-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DropdownComponent,
    },
  ],
  imports: [
    CommonModule,
    DropdownModule,
    ReactiveFormsModule,
    FormsModule,
    SpinnerIcon,
    TooltipModule,
    AutoFocusModule,
    DropdownItemComponent,
    LoaderModule,
    SvgIconModule,
  ],
})
export class DropdownComponent extends Dropdown implements ControlValueAccessor {
  @Input() override set placeholder(val: string) {
    this._placeholder.set(val);
  }

  override get placeholder(): Signal<string> {
    return this._placeholder.asReadonly();
  }

  override _placeholder = signal(CommonMessages.ChoosePlaceholder);

  constructor(
    override el: ElementRef,
    override renderer: Renderer2,
    override cd: ChangeDetectorRef,
    override zone: NgZone,
    override filterService: FilterService,
    override config: PrimeNGConfig,
  ) {
    super(el, renderer, cd, zone, filterService, config);
  }
}
