<p-accordion
    #accordion
    class="state-{{ error ? 'error' : colorMode }}"
    (onOpen)="onOpenClicked($event)"
    (onClose)="onCloseClicked($event)"
>
    <p-accordionTab [disabled]="disabled" [selected]="!loading && isOpen()" [cache]="cache">
        <ng-template pTemplate="icon" let-selected>
            <ui-svg
                [icon]="selected ? 'Chevron Down' : 'Chevron Right'"
                [strokeColor]="colorMode === 'white' ? 'stroke-black' : 'stroke-white'"
                class="inline-flex"
            />
        </ng-template>
        <ng-template pTemplate="header">
            <div class="flex h-[24px] w-full gap-2">
                <div class="header-icon">
                    <ng-content select="header-icon"></ng-content>
                </div>
                <div class="header-left">
                    <span class="self-center text-md leading-5">{{ header }}</span>
                    <ng-content select="header-left"></ng-content>
                    <ui-message *ngIf="error" severity="error" [title]="commonMessages.CannotLoadData" />
                    <ui-message *ngIf="!error && displayMessage" [severity]="messageSeverity" [title]="messageTitle" />
                </div>
                <div class="header-actions">
                    <ui-loader *ngIf="loading" variant="ring" />
                    <ng-content select="header-right"></ng-content>
                </div>
            </div>
        </ng-template>
        <div class="content-wrapper">
            <ui-accordion-content [colorMode]="colorMode" [styleClass]="contentStyleClass">
                <div *ngIf="error" class="flex w-full justify-center">
                    <ui-message severity="error" />
                </div>
                <ng-content></ng-content>
            </ui-accordion-content>
        </div>
    </p-accordionTab>
</p-accordion>
<div
    *ngIf="mode === displayMode.Stacked"
    class="stack"
    [ngClass]="[openStacked ? 'h-0' : 'h-1.5', error ? 'bg-rose-500' : 'bg-primary-500']"
></div>
