<ng-template [ngIf]="state">
    <div class="d--grid columns--2 gap--1">
        <div class="dc-datepicker__input">
            <div class="datepicker">
                <div class="datepicker__header">
                    <a class="dc-btn dc-btn--faced" (click)="handleNextMonth()">
                        <i class="dc-icon dc-icon__chevron-left"></i>
                    </a>
                    <div class="datepicker__header--title">
                        <span class="datepicker__header--month">{{ months[state.value.month - 1] }}</span>
                        <span>&nbsp;</span>
                        <span class="datepicker__header--year">{{ state.value.year }}</span>
                    </div>
                    <a class="dc-btn dc-btn--faced" (click)="handlePreviousMonth()">
                        <i class="dc-icon dc-icon__chevron-right"></i>
                    </a>
                </div>
                <div class="datepicker__calendar">
                    <span>PO</span><span>ÚT</span><span>ST</span><span>ČT</span><span>PÁ</span><span>SO</span
                    ><span>NE</span>
                    <!--
  JK > todo:
    - den weekendu  => přidat class="weekend"
    - den předchozího měsíce  => přidat class="day--prev-month"
    - den následujícího měsíce  => přidat class="day--next-month"
-->
                    <ng-template ngFor let-day [ngForOf]="state.days">
                        <a
                            [ngClass]="{
                                day: true,
                                '--current-month': day.isCurrent,
                                '--not-current-month': !day.isCurrent,
                                '--today':
                                    state.now.day === day.value &&
                                    state.now.month === state.value.month &&
                                    state.now.year === state.value.year &&
                                    day.isCurrent,
                                '--selected': state.value.day === day.value && day.isCurrent,
                            }"
                            (click)="handleSelectDay(day)"
                            >{{ day.value }}</a
                        >
                    </ng-template>
                </div>
            </div>
        </div>
        <div class="dc-datepicker__output">
            <div class="dc-form__group">
                <label> Výběr měsíce a roku </label>
                <select class="c-form-control w--50 mr--1-2" (change)="handleSelectMonth($event)">
                    <option
                        [value]="month"
                        [selected]="state.value.month === month"
                        *ngFor="let month of state.avaibleMonths"
                    >
                        {{ months[month - 1] }}
                    </option>
                </select>
                <select class="c-form-control w--40" (change)="handleSelectYear($event)">
                    <option
                        [value]="year"
                        [selected]="state.value.year === year"
                        *ngFor="let year of state.avaibleYears"
                    >
                        {{ year }}
                    </option>
                </select>
            </div>
            <div class="dc-form__group mb--2" *ngIf="showTimePicker">
                <label> Výběr hodiny a minuty </label>
                <select class="c-form-control w--30 mr--1-2" (change)="handleSelectHour($event)">
                    <option
                        [value]="hour"
                        [selected]="state.value.hours === hour"
                        *ngFor="let hour of state.avaibleHours"
                    >
                        {{ hour }}
                    </option>
                </select>
                <select class="c-form-control w--30" (change)="handleSelectMinute($event)">
                    <option
                        [value]="minute"
                        [selected]="state.value.minutes === minute"
                        *ngFor="let minute of state.avaibleMinutes"
                    >
                        {{ minute }}
                    </option>
                </select>
            </div>
            <div class="dc-datepicker__controls" *ngIf="showTodayBtn">
                <ui-button-legacy (click)="handleSelectToday()"> Dnes </ui-button-legacy>
                <!--
                <button class="dc-btn dc-btn--primary btn-effect--pulse" (click)="handleClose()">
                    <span>Zavřít</span>
                </button>
                -->
            </div>
        </div>
    </div>

    <a class="dc-btn dc-btn--faced dc-datepicker__close" (click)="handleClose()">
        zavřít <i class="fa fa-fw fa-close"></i>
    </a>
</ng-template>
