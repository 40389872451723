import { Directive, TemplateRef } from '@angular/core';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[formLabel]' })
export class FormLabelDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

// todo: do separatniho souboru form-utils
export function getFormLabel(label: string, labelTemplate: FormLabelDirective): string | TemplateRef<any> {
  if (label) {
    return label;
  }
  return labelTemplate ? labelTemplate.templateRef : '';
}
