import { BlockUIModule } from 'primeng/blockui';
import { BlockUiContainerComponent } from './block-ui-container.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [BlockUiContainerComponent],
  imports: [BlockUIModule, CommonModule],
  exports: [BlockUiContainerComponent],
})
export class BlockUiContainerModule {}
