import { Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, publishReplay, refCount } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Permissions } from './model/access-control.model';
import { Router } from '@angular/router';
import { User } from '../user/model/user.model';
import { UserService } from '../user/user.service';

@Injectable()
export class AccessControlService {
  credentials$: Observable<Permissions>;

  constructor(
    protected router: Router,
    protected user: UserService,
  ) {
    this.credentials$ = user.user$.pipe(
      filter((_user) => !!_user && !!(<User>_user).memberOf),
      distinctUntilChanged((a, b) => (<User>a).username === (<User>b).username),
      map((_user) => (<User>_user).memberOf),
      publishReplay(1),
      refCount(),
    ) as unknown as Observable<Permissions>;

    this.credentials$.subscribe();
  }

  getCredentials$(): Observable<Permissions> {
    return this.credentials$;
  }

  hasPermissions$(permissions: string[]): Observable<boolean[]> {
    return this.credentials$.pipe(map((credentials) => permissions.map((_) => !!credentials[_])));
  }

  hasPermission$(permission: string): Observable<boolean> {
    return permission ? this.credentials$.pipe(map((i) => !!i[permission])) : of(true);
  }
}
