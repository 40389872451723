export const enum LoadingState {
  INIT = 'INIT',
  LOADING = 'LOADING',
  STORING = 'STORING',
  LOADED = 'LOADED',
  STORED = 'STORED',
}
export interface ErrorState {
  errorMsg: string;
}

export type CallState = LoadingState | ErrorState;

export function getError(callState: CallState): LoadingState | string | null {
  const err = callState as ErrorState;
  if (err?.errorMsg !== undefined) {
    return err.errorMsg;
  }
  return null;
}
