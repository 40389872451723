import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

export type HeadingScope = 'page' | 'section' | 'column' | 'fieldset';

@Component({
  selector: 'ui-heading-legacy',
  template: `<h1 [class]="className"><ng-content></ng-content></h1>`,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'd--block' },
})
export class HeadingComponent {
  className!: string;

  @Input()
  set scope(scope: string) {
    if (scope) this.className = `c-section__title c-section__title--${scope}`;
  }
}
