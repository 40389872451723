import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, from, mergeMap } from 'rxjs';

import { ExcludedUrlRegex } from 'keycloak-angular/lib/core/interfaces/keycloak-options';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  constructor(private keycloak: KeycloakService) {}

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const { enableBearerInterceptor, excludedUrls } = this.keycloak;
    const modified = req.clone({ setHeaders: { 'x-source': 'CORE_FRONTEND' } });
    if (!enableBearerInterceptor) {
      return next.handle(modified);
    }

    const shallPass = excludedUrls.findIndex((item) => this.isUrlExcluded(req, item)) > -1;
    if (shallPass) {
      return next.handle(req);
    }

    return from(this.conditionallyUpdateToken(modified)).pipe(
      mergeMap(() =>
        this.keycloak.isLoggedIn() ? this.handleRequestWithTokenHeader(modified, next) : next.handle(modified),
      ),
    );
  }

  /**
   * Calls to update the keycloak token if the request should update the token.
   *
   * @param req http request from @angular http module.
   * @returns
   * A promise boolean for the token update or noop result.
   */
  private async conditionallyUpdateToken(req: HttpRequest<unknown>): Promise<boolean> {
    // pokud nastane eventa expirace
    if (this.keycloak.shouldUpdateToken(req)) {
      return await this.keycloak.updateToken();
    }
    return true;
  }

  /**
   * @deprecated
   * Checks if the url is excluded from having the Bearer Authorization
   * header added.
   *
   * @param req http request from @angular http module.
   * @param excludedUrlRegex contains the url pattern and the http methods,
   * excluded from adding the bearer at the Http Request.
   */
  private isUrlExcluded({ method, url }: HttpRequest<unknown>, { urlPattern, httpMethods }: ExcludedUrlRegex): boolean {
    const httpTest = httpMethods.length === 0 || httpMethods.join().indexOf(method.toUpperCase()) > -1;

    const urlTest = urlPattern.test(url);

    return httpTest && urlTest;
  }

  private handleRequestWithTokenHeader(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.keycloak.addTokenToHeader(req.headers).pipe(
      mergeMap((headersWithBearer) => {
        const kcReq = req.clone({ headers: headersWithBearer });
        return next.handle(kcReq);
      }),
    );
  }
}
