import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

export function setErrorFilter(control: AbstractControl, errorFilter: any) {
  if (errorFilter === 'touched') {
    errorFilter = (c: any) => c.touched;
  } else if (errorFilter === 'dirtyTouched') {
    errorFilter = (c: any) => c.dirty && c.touched;
  }

  if (control instanceof UntypedFormGroup) {
    Object.keys(control.controls).forEach((key) => setErrorFilter(control.controls[key], errorFilter));
  } else if (control instanceof UntypedFormArray) {
    control.controls.forEach((_control) => setErrorFilter(_control, errorFilter));
  }
  (control as any).errorFilter = errorFilter;
}

export function formEventValue(event: Event) {
  if (typeof event.target === 'undefined') {
    throw new Error(`Form event must have target`);
  }
  return (event.target as any).value;
}

export const dayLabels = {
  su: 'Ne',
  mo: 'Po',
  tu: 'Ut',
  we: 'St',
  th: 'Ct',
  fr: 'Pa',
  sa: 'So',
};

export const monthLabels = {
  1: 'Leden',
  2: 'Únor',
  3: 'Březen',
  4: 'Duben',
  5: 'Květen',
  6: 'Červen',
  7: 'Červenec',
  8: 'Srpen',
  9: 'Září',
  10: 'Říjen',
  11: 'Listopad',
  12: 'Prosinec',
};

export function forEachFormControls(
  group: UntypedFormGroup | UntypedFormArray,
  fn: (a: AbstractControl) => void,
): void {
  Object.keys(group.controls).forEach((key: string) => {
    // @ts-ignore
    const abstractControl = group.controls[key] as AbstractControl;
    if (abstractControl instanceof UntypedFormGroup || abstractControl instanceof UntypedFormArray) {
      forEachFormControls(abstractControl, fn);
    } else {
      fn(abstractControl);
    }
  });
}

export function clearAllValidators(group: UntypedFormGroup | UntypedFormArray): void {
  Object.keys(group.controls).forEach((key: string) => {
    // @ts-ignore
    const abstractControl = group.controls[key];
    if (abstractControl instanceof UntypedFormGroup || abstractControl instanceof UntypedFormArray) {
      clearAllValidators(abstractControl);
    } else {
      abstractControl.clearValidators();
    }
  });
}

export function atLeastOneFilledValidator(
  formGroup: UntypedFormGroup,
  specificFieldNames?: string[],
): ValidatorFn | null {
  // @ts-ignore
  return (group: UntypedFormGroup): ValidationErrors | null => {
    const controls = group.controls;
    if (controls) {
      const keys = specificFieldNames ? specificFieldNames : Object.keys(controls);
      const oneFilled = keys.find((field) => !!controls[field].value);
      if (!oneFilled) {
        return {
          atLeastOneRequired: true,
        };
      }
    }
    return null;
  };
}

export type ControlsOf<T extends Record<any, any>> = {
  [K in keyof T]-?: T[K] extends Array<infer R>
    ? FormArray<R extends Record<any, any> ? FormGroup<ControlsOf<R>> : FormControl<R>>
    : T[K] extends Record<any, any>
      ? FormGroup<ControlsOf<T[K]>>
      : FormControl<T[K]>;
};

export type FormValue<T extends AbstractControl> = T extends AbstractControl<infer TValue, any> ? TValue : never;
// Use FormRawValue if you don't disable controls on your form and want to ignore the `Partial<...>` of FormValue.
export type FormRawValue<T extends AbstractControl> =
  T extends AbstractControl<any, infer TRawValue> ? TRawValue : never;
export type FormObject<T extends { [K in keyof T]: AbstractControl<any, any> }> = FormValue<FormGroup<T>>;
