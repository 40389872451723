import { Button, ButtonModule } from 'primeng/button';
import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit } from '@angular/core';
import { NgClass, NgIf, NgStyle, NgTemplateOutlet } from '@angular/common';

import { SpinnerIcon } from 'primeng/icons/spinner';

@Component({
  selector: 'ui-button',
  templateUrl: './button.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ButtonModule, NgStyle, NgClass, NgTemplateOutlet, NgIf, SpinnerIcon],
})
export class ButtonComponent extends Button implements OnInit {
  @Input() override styleClass!: string;

  constructor(override el: ElementRef) {
    super(el);
  }

  ngOnInit(): void {
    this.assignStyleClassToProperty();
  }

  private assignStyleClassToProperty(): void {
    const classes = this.styleClass?.split(' ');
    if (!classes?.length) {
      return;
    }

    classes.forEach((cls: string) => {
      switch (cls) {
        case 'p-button-outlined':
          this.outlined = true;
          break;
        case 'p-button-text':
          this.text = true;
          break;
        case 'p-button-icon-only':
          this.label = undefined;
          break;
        case 'p-button-rounded':
          this.rounded = true;
      }
    });
  }
}
