import { AddressSuggestion, ShortAddress, ValidateAddressResult } from './address.model';
import { ApiService, ObserveResponseOptions } from '@framework';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class AddressApiService {
  ttl: Map<string, number> = new Map();
  lifeTime = 60 * 60 * 1000;

  opt = {
    addressLookupPath: `/api/latest/rest/utils/address-whisperer/get-address`,
    addressValidationPath: `/api/latest/rest/utils/address-whisperer/validate-address`,
  };

  constructor(private api: ApiService) {}

  lookupAddress(address: any, attribute: string): Observable<AddressSuggestion[]> {
    let fieldTypeId;
    let responseAttribute = attribute;
    switch (attribute) {
      case 'street':
        fieldTypeId = 1;
        break;
      case 'streetNumber':
        fieldTypeId = 2;
        responseAttribute = 'number';
        break;
      case 'city':
        fieldTypeId = 3;
        break;
      case 'zip':
      case 'zipCode':
        fieldTypeId = 4;
        break;
      default:
        throw Error(`Unknow address ${attribute}`);
    }
    const postData = {
      fieldType: fieldTypeId,
      address: {
        number: address.streetNumber,
        street: address.street,
        city: address.city,
        zip: address.zip,
      },
      limit: 50,
    };
    const params: ObserveResponseOptions = { observe: 'response' };
    return this.api.post(this.opt.addressLookupPath, postData, params).pipe(
      map((res: HttpResponse<any>) => {
        if (res.status !== 200) {
          throw Error(`Address Lookup Error`);
        }
        const data = res.body;
        return data.address.map((payload: any) => {
          return {
            label:
              attribute === 'streetNumber'
                ? payload[responseAttribute] + ' - ' + payload.city
                : payload[responseAttribute],
            value: payload[responseAttribute],
            payload,
          };
        });
      }),
      catchError((_) => {
        return observableOf([]);
      }),
    );
  }

  checkAddress(address: ShortAddress): Observable<any> {
    const { street, streetNumber, city, zipCode } = address;
    if (!street || !streetNumber || !city || !zipCode) return observableOf(false);
    const payload = {
      address: { wholeAddress: `${street} ${streetNumber} ${city} ${zipCode}` },
    };
    const params: ObserveResponseOptions = { observe: 'response' };
    return this.api.post(this.opt.addressValidationPath, payload, params).pipe(
      map((res: HttpResponse<any>) => {
        if (res.status !== 200) {
          throw Error(`Check Address Error`);
        }
        return res.body;
      }),
      map((data) => validateAddressDecodeResponse(data)),
    );
  }
}

export function validateAddressDecodeResponse(response: any): ValidateAddressResult {
  const resultType = response.result.type;
  switch (resultType) {
    case 'HIT':
      return {
        resultType: 'ok',
        error: response.errorMessage,
        hint: response.result.hint,
        addressCode: response.result.addresses[0].values.CODE,
      };
    case 'APPROXIMATE':
      return {
        resultType: 'ambiguous',
        error: response.errorMessage,
        hint: response.result.hint,
      };
    case 'PARTIAL_HIT':
      return {
        resultType: 'incomplete',
        error: response.errorMessage,
        hint: response.result.hint,
      };
    case 'NOTHING':
      return {
        resultType: 'invalid',
        error: response.errorMessage,
        hint: response.result.hint,
      };
    case 'TOO_MANY':
      return {
        resultType: 'ambiguous',
        error: 'Adresa není jednoznačná',
        hint: response.result.hint,
      };
    default:
      return {
        resultType: 'error',
        error: 'Adresu nelze ověřit.',
        hint: response.result.hint,
        addressCode: undefined,
      };
  }
}
