<p-dialog
    [visible]="display"
    (visibleChange)="onReject()"
    [modal]="true"
    [closable]="closable"
    [closeOnEscape]="closeOnEsc"
    [styleClass]="styleClass"
    [contentStyleClass]="contentClass"
    [draggable]="draggable"
    [resizable]="false"
    [maximizable]="maximizable"
    [position]="position"
    [focusOnShow]="false"
    [appendTo]="appendTo"
    (onShow)="onAppear()"
    (onHide)="onDisappear()"
>
    <ng-template pTemplate="header">
        {{ header }}
        <ng-content select="header"></ng-content>
    </ng-template>
    <p-blockUI [blocked]="loading || storing || blocked" [target]="blockUiContainer"></p-blockUI>
    <ui-block-ui-container #blockUiContainer>
        <div
            class="p-dialog-content-container w-full"
            [ngClass]="{
                ready: !loading && !storing,
                loading: loading,
                saving: storing,
            }"
        >
            <ui-loader *ngIf="loading || storing" />
            <ng-content></ng-content>
        </div>
    </ui-block-ui-container>
    <ng-template pTemplate="footer">
        <div class="flex grow justify-start gap-2">
            <ng-content select="footer-left"></ng-content>
        </div>
        <div class="flex grow justify-end gap-2">
            <ui-button
                *ngIf="showRejectButton"
                styleClass="p-button-secondary p-button-text"
                (onClick)="onReject()"
                [label]="rejectButtonLabel"
            />
            <ui-button
                *ngIf="showAcceptButton"
                [disabled]="disabled"
                [loading]="storing"
                [pTooltip]="acceptButtonTooltip"
                tooltipPosition="top"
                (onClick)="onAccept()"
                [label]="acceptButtonLabel"
            />
            <ng-content select="footer-right"></ng-content>
        </div>
    </ng-template>
</p-dialog>
