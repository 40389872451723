import { AccessControlService, UserService } from '@framework';
import { ActivatedRoute, Router } from '@angular/router';
import { CdkDragEnd, CdkDragStart } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { filter, switchMap } from 'rxjs/operators';

import { LocalStorage } from 'angular-web-storage';
import { of } from 'rxjs';

type Position = 'TL' | 'TR' | 'BL' | 'BR';

interface FloatingButtonPosition {
  direction: Position;
  x: number;
  y: number;
}

@Component({
  selector: 'ui-floating-button-legacy',
  templateUrl: './floating-button.component.html',
  styleUrls: ['./floating-button.component.scss'],
})
export class FloatingButtonComponent implements OnInit {
  isDragged = false;
  showButton: boolean;
  element = { width: 56, height: 56 };

  @LocalStorage('position')
  position: FloatingButtonPosition = { direction: 'BR', x: 50, y: 50 };

  constructor(
    private readonly accessControl: AccessControlService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.userService
      .isLogged$()
      .pipe(
        filter((e) => e),
        switchMap((log) => (log ? this.accessControl.hasPermission$('coreAccess-BP-entryForms') : of(false))),
      )
      .subscribe((enabled) => (this.showButton = enabled));
  }

  drag(_: CdkDragStart) {
    this.isDragged = true;
  }

  drop(event: CdkDragEnd) {
    const boundary = this.getBoundary();

    const boundingClientRect = event.source.element.nativeElement.getBoundingClientRect() as DOMRect;
    const x = boundingClientRect.x;
    const y = boundingClientRect.y;

    const direction = (boundary.height / 2 > y ? 'T' : 'B') + (boundary.width / 2 > x ? 'L' : 'R');
    const correction = {
      x: direction.includes('L') ? 0 : this.element.width,
      y: direction.includes('T') ? 0 : this.element.height,
    };

    this.position = {
      direction: direction as Position,
      x: (boundary.width / 2 > x ? x : boundary.width - x) - correction.x,
      y: (boundary.height / 2 > y ? y : boundary.height - y) - correction.y,
    };

    event.source._dragRef.reset();
  }

  calculatePosition() {
    const { x, y, direction } = this.position;
    const boundary = this.getBoundary();

    const safeCoordinates = {
      x: x + this.element.width > boundary.width ? boundary.width - this.element.width : x,
      y: y + this.element.height > boundary.height ? boundary.height - this.element.height : y,
    };

    switch (direction) {
      case 'TL':
        return { 'top.px': safeCoordinates.y, 'left.px': safeCoordinates.x };
      case 'TR':
        return { 'top.px': safeCoordinates.y, 'right.px': safeCoordinates.x };
      case 'BL':
        return { 'bottom.px': safeCoordinates.y, 'left.px': safeCoordinates.x };
      case 'BR':
        return {
          'bottom.px': safeCoordinates.y,
          'right.px': safeCoordinates.x,
        };
      default:
        throw new Error('Unknown direction.');
    }
  }

  openModal() {
    if (this.isDragged) {
      this.isDragged = false;
      return;
    }
    this.router.navigate([{ outlets: { modal: 'novy-pozadavek' } }], {
      relativeTo: this.route.parent,
    });
  }

  getBoundary() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
}
