import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, tap } from 'rxjs/operators';

import { AccessControlService } from './access-control.service';
import { Observable } from 'rxjs';
import { inject } from '@angular/core';

export const AccessControlGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const router: Router = inject(Router);
  const accessControlService: AccessControlService = inject(AccessControlService);

  let roles = route.data['roles'] as Array<string>;
  return accessControlService.hasPermissions$(roles).pipe(
    map((t) => t.every(Boolean)),
    tap((t) => {
      if (!t) router.navigate(['permission-denied']);
    }),
  );
};
