import { AccordionComponent } from './accordion.component';
import { AccordionContentComponent } from './accordion-content/accordion-content.component';
import { CommonModule } from '@angular/common';
import { LoaderModule } from '../../non-primeng/loader/loader.module';
import { MessageModule } from '../message/message.module';
import { NgModule } from '@angular/core';
import { AccordionModule as PrimeAccordionModule } from 'primeng/accordion';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SvgIconModule } from '../../non-primeng/svg-icon/svg-icon.module';

@NgModule({
  imports: [PrimeAccordionModule, CommonModule, ProgressSpinnerModule, LoaderModule, MessageModule, SvgIconModule],
  declarations: [AccordionComponent, AccordionContentComponent],
  exports: [AccordionComponent],
})
export class AccordionModule {}
