import { Component, Input, OnChanges, TemplateRef } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[uiElementOutletLegacy]',
  template: `{{ content }}<ng-template [ngTemplateOutlet]="contentTemplate"></ng-template>`,
})
export class ElementOutletComponent implements OnChanges {
  @Input() uiElementOutletLegacy!: string | TemplateRef<any>;

  content!: string;
  contentTemplate!: TemplateRef<any>;

  ngOnChanges() {
    if (this.uiElementOutletLegacy instanceof TemplateRef) {
      [this.content, this.contentTemplate] = ['', <TemplateRef<any>>this.uiElementOutletLegacy];
    } else {
      // @ts-ignore
      [this.content, this.contentTemplate] = [<string>this.uiElementOutletLegacy, null];
    }
  }
}
