import dayjs from 'dayjs';
import isLeapYear from 'dayjs/plugin/isLeapYear';
dayjs.extend(isLeapYear);

export function isPersonalInInvalid(formattedPersonalIn: string): boolean | null {
  if (!formattedPersonalIn) return null;

  const allNonNumericCharacters = /[^0-9]/g;
  const value: string = formattedPersonalIn.replace(allNonNumericCharacters, '');

  if (!value) return true;

  if (value.length < 9 || value.length > 10) return true;

  const year = parseInt(value.substring(0, 2), 10);
  let wholeYear = year;
  let month = parseInt(value.substring(2, 4), 10);
  const day = parseInt(value.substring(4, 6), 10);

  if (month < 1 || month > 82 || day < 1 || day > 31) return true;

  wholeYear += value.length === 9 || (value.length === 10 && year >= 54) ? 1900 : 2000;

  // Personal id should be 10 numbers long from 1.1.1954.
  if (value.length === 9 && wholeYear >= 1954) return true;

  // Personal id should be 9 numbers long until 31.12.1953.
  if (value.length === 10 && wholeYear < 1954) return true;

  // Women usually have their personal id year composed as their year of birth + 50
  if (month >= 51 && month <= 62) month -= 50;

  // Men can have their personal id year composed as their year of birth + 20 (it's possible from year 2004)
  if (month >= 21 && month <= 32 && wholeYear >= 2004) month -= 20;

  // Women can also have their personal id year composed as their year of birth + 70 (it's possible from year 2004)
  if (month >= 71 && month <= 82 && wholeYear >= 2004) month -= 70;

  // Personal number is invalid if no conditions were met
  if (month > 12) return true;

  // Month is 0-indexed
  const dateOfBirth = new Date(wholeYear, month - 1, day);

  // Check if provided date of birth is in future
  if (dayjs(dateOfBirth).isAfter(new Date(), 'days')) return true;

  // Because of bug in date-fns "isValid" function, check valid days of month in switch
  switch (month) {
    case 2: {
      const isLeap = dayjs(dateOfBirth).isLeapYear();
      if ((isLeap && day > 29) || (!isLeap && day > 28)) return true;
      break;
    }
    case 1:
    case 3:
    case 5:
    case 7:
    case 8:
    case 10:
    case 12:
      if (day > 31) return true;
      break;
    case 4:
    case 6:
    case 9:
    case 11:
    default:
      if (day > 30) return true;
  }

  // If length is 9, and before 54 year, then assume as correct personal id
  if (value.length === 9 && year < 54) return null;

  // If personalId mod 11 === 0, then it's a valid personal id
  if (parseInt(value, 10) % 11 === 0) return null;

  // It can happen, that valid personal id mod 11 is not a zero then...
  // 1. Take mod 11 of first 9 numbers (eq. X)
  // 2. If the X is equal to 10, then the last number of value must be 0
  const lastNumber = parseInt(value[value.length - 1], 10);
  const firstNineNumbers = value.substring(0, 9);
  const rest = parseInt(firstNineNumbers, 10) % 11;

  // If rest === 10, the last number must be 0
  if (rest === 10) return lastNumber !== 0;

  return true;
}
